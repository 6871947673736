import { IDefaultsModel, model as appModel } from "model";
import { Input, Label } from "reactstrap";

export const DefaultsEditor = ({ model }: { model: IDefaultsModel }) => {
    const { master } = appModel.useAuditCtx();

    return <>
        <div className="form-group col-lg-6 col-md-12 mb-1">
            <Label for='heated'>Heated:</Label>
            <Input id='heated' value={model.heated} onChange={(e) => model.setHeated(e.target.value) } type='select' required={model.required.heated}> 
                <option></option>
                {master.heated.map((e) => <option key={e.id} value={e.id}>{e.label}</option>)}
            </Input>
        </div>

        <div className="form-group col-lg-6 col-md-12 mb-1">
            <Label for='ac'>A/C:</Label>
            <Input id='ac' value={model.ac} onChange={(e) => model.setAc(e.target.value) } type='select' required={model.required.ac}> 
                <option></option>
                {master.ac.map((e) => <option key={e.id} value={e.id}>{e.label}</option>)}
            </Input>
        </div>

        <div className="form-group col-lg-6 col-md-12 mb-1">
            <Label for='burnHours'>Burn Hours:</Label>
            <Input 
                id='burnHours' 
                value={model.burnHours} 
                onChange={e => model.setBurnHours(e.target.value)}  
                autoFocus
                type="number"
                min={1}
                step={1}
                required={model.required.burnHours}
            />
        </div>

        <div className="form-group col-lg-6 col-md-12 mb-1">
            <Label for='fixtureHeight'>Fixture Height:</Label>
            <Input 
                id='fixtureHeight' 
                value={model.fixtureHeight} 
                onChange={e => model.setFixtureHeight(e.target.value)}  
                autoFocus
                type="number"
                min={1}
                step={1}
                required={model.required.fixtureHeight}
            />
        </div>

        <div className="form-group col-lg-6 col-md-12 mb-1">
            <Label for='ceilingHeight'>Ceiling Height:</Label>
            <Input 
                id='ceilingHeight' 
                value={model.ceilingHeight} 
                onChange={e => model.setCeilingHeight(e.target.value)}  
                autoFocus
                type="number"
                min={1}
                step={1}
                required={model.required.ceilingHeight}
            />
        </div>

        <div className="form-group col-lg-6 col-md-12 mb-1">
            <Label for='voltage'>Voltage:</Label>
            <Input id='voltage' value={model.voltage} onChange={(e) => model.setVoltage(e.target.value) } type='select' required={model.required.voltage}> 
                <option></option>
                {master.voltage.map((e) => <option key={e.id} value={e.id}>{e.label}</option>)}
            </Input>
        </div>
    </>;
}