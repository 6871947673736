import { EV } from 'enerx-shared';
import { model } from 'model';
import { useState } from 'react';
import { ArrowLeft, Edit, Grid, AlignJustify, X, Plus } from 'react-feather';
import { useHistory } from 'react-router-dom';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Row, Table } from 'reactstrap'
import { AddEditArea } from './AddEditArea';
import { AuditDefaultsForm } from './AuditDefaultsForm';
import { AddEditLineItem } from 'views/item/AddEditLineItem';

export const ManageAreas = () => {
    const [view, setView] = useState<'list'|'grid'>(window.screen.width > 576 ? 'list' : 'grid');
    const [modal, setModal] = useState<{ mode: 'none' | 'area' | 'defaults' | 'line', area?: EV.IArea }>({ mode: 'none' });
    const history = useHistory();
    const { activeAuditAreas, removeArea, master, activeAuditExistings, activeAudit } = model.useAuditCtx();
    const lineItemModel = model.useLineItem(master, activeAuditExistings, activeAudit.fsid, modal.area?.fsid);
    const facilityDefaultsSet = activeAudit && (
        activeAudit.ac 
        || activeAudit.burnHours 
        || activeAudit.fixtureHeight
        || activeAudit.ceilingHeight
        || activeAudit.voltage
    );

    return (
    <>
        <Row>
            <Col sm={6}>
                <h2>Manage Audit Areas</h2>
            </Col>
            <Col sm={6} className='text-end'>
                <Button color='primary' outline onClick={() => history.push(`/audit/${activeAudit.fsid}`)} size='sm'><ArrowLeft size={16}/>Back to Audit</Button>
                <Button color='primary' outline={view != 'list'} className='m-25 p-25'><AlignJustify className='m-0 p-0' size={24} onClick={() => setView('list')}/></Button>
                <Button color='primary' outline={view != 'grid'} className='m-25 p-25'><Grid className='m-0 p-0' size={24} onClick={() => setView('grid')}/></Button>
            </Col>
        </Row>
        <br/>
        <br/>
        {view == 'list' && activeAuditAreas?.length > 0 && <Table responsive>
            <thead>
                <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Heated</th>
                <th></th>
                </tr>
            </thead>
            <tbody> 
            {
                activeAuditAreas.map(area => (
                    <tr key={area.fsid}>
                        <td>{area.name}</td>
                        <td>{master.areaType?.find(a => a.id == area.areaType)?.label ?? ''}</td>
                        <td>{master.heated?.find(a => a.id == area.heated)?.label ?? ''}</td>
                        <td>
                            <div className='text-end'>
                                <Button size="sm" outline color="primary" className="me-1" onClick = {() => setModal({ mode: 'line', area })}><Plus size={16}/></Button>
                                <Button size="sm" outline color="info" className="me-1" onClick = {() => setModal({ mode: 'area', area })}><Edit size={16}/></Button>
                                <Button size="sm" outline color="danger" className="me-1" onClick = {() => removeArea(area.fsid)}><X size={16}/></Button>
                            </div>
                        </td>
                    </tr>
                ))
            }
            </tbody>
        </Table>
        }
        {view == 'grid' && 
        <Row className='match-height'> {
            activeAuditAreas ? activeAuditAreas.map(area => (
                <Col lg={3} md={4} sm={6} xs={12} key={area.fsid}>
                <Card key={area.fsid} className='p-1'>
                    <CardBody className='p-50'>
                    <Row>
                        <Col xs={12}><h5><b>Name:</b> {area.name}</h5></Col>
                        <Col xs={12}><h5><b>Type:</b> {master.areaType?.find(a => a.id == area.areaType)?.label ?? ''}</h5></Col>
                        <Col xs={12}><h5><b>Heated:</b> {master.heated?.find(a => a.id == area.heated)?.label ?? ''}</h5></Col>
                    </Row>
                    </CardBody>
                    <CardFooter className='p-50 pt-1'>
                    <Row>
                        <Col xs={3}>
                            <Button size="sm" outline color="danger" onClick = {() => removeArea(area.fsid)}><X size={16}/></Button>
                        </Col>
                        <Col xs={9} className='text-end'>
                            <Button size="sm" outline color="primary" className="me-1" onClick = {() => setModal({ mode: 'area', area })}><Edit size={16}/></Button>
                            <Button size="sm" outline color="dark" onClick = {() => setModal({ mode: 'line', area })}><Plus size={16}/></Button>
                        </Col>
                    </Row>
                    </CardFooter>
                </Card>
                </Col>
            )) : null}
.        </Row>        
        }
        
        <Row className='match-height'>
        {
            !facilityDefaultsSet && activeAuditAreas && activeAuditAreas.length == 0 && <Col xs={12} sm={6} lg={{ offset: 1, size: 4 }}>
                    <Card className="bg-light-secondary">
                        <CardHeader>
                            <h3 className="text-primary m-1 text-center">
                                Facility defaults will automatically fill in Area and Line item fields for this audit. 
                                When Area defaults are added, they will be used instead of Facility defaults.
                            </h3>
                        </CardHeader>
                        <CardBody className='d-flex flex-column'>
                            <div className="flex-grow-1"></div> 
                            <Button color="dark" className='align-self-end' block onClick={() => setModal({ mode: 'defaults' })}>Add Facility Defaults</Button>
                        </CardBody>
                    </Card>
                </Col>
        }
        {
            activeAuditAreas && activeAuditAreas.length == 0 && <Col xs={12} sm={6} lg={{ offset: 1, size: 4 }}>
                <Card className="bg-light-primary">
                    <CardHeader>
                        <h3 className="text-primary m-1 text-center">
                            To begin your audit, create an area. Setting Area defaults automatically adds values to new line items created within that Area.
                        </h3>
                    </CardHeader>
                    <CardBody className='d-flex flex-column'>
                        <div className="flex-grow-1"></div> 
                        <Button color="primary" className='align-self-end' block onClick={() => setModal({ mode: 'area' })}>Add Area</Button>
                    </CardBody>
                </Card>
            </Col>
        }

            </Row>

        {
            modal.mode == 'area' && <AddEditArea origin={modal.area} toggle={() => setModal({ mode: 'none' })}></AddEditArea>
        }
        {
            modal.mode == 'defaults' && <AuditDefaultsForm original={activeAudit} toggle={() => setModal({ mode: 'none' })} />
        }
        {
            activeAuditAreas && activeAuditAreas.length > 0 && <Row>
                <Col className='text-end'>
                    <Button color='dark' onClick={() => setModal({ mode: 'defaults' })}>Add Facility Defaults</Button>
                    <Button color='primary' className='ms-1' onClick={() => setModal({ mode: 'area' })}>Add Area</Button>
                </Col>
            </Row>
        }
        {
            modal.mode == 'line' && <AddEditLineItem 
                model={lineItemModel} 
                onComplete={(status?: 'cancel'|'ok') => { 
                    setModal({ mode: 'none' }); 
                    console.log('Added', status);
                }} 
                selectedArea={modal.area}
                initialMode='props'
            />
        }

    </>);
}