import { useAppCtx } from "model/ctx";
import { useEffect, useMemo, useState } from "react";
import { EV } from 'enerx-shared';
import BlobReduce from 'image-blob-reduce';
import { db } from "./db";
import { UpdateUtils } from "model/utils";
import { Fstore, PhotoDbCol } from "fb-web";
import { PhotoManager } from "./mgr";
import { confirmations } from "utility/confirmations";
import { toast } from "react-toastify";
import { Api } from "model/api";
import * as CryptoJS from 'crypto-js';
import { ILocalEnv } from "model/local.env";
import { ILocalPhoto, ProgressStatus } from "./types";
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

let lastPosition: GeolocationPosition;
let currentOrientation: { alpha: number, beta: number, gamma: number };

navigator.geolocation.watchPosition(crd => { // better to put it under useGeoRequestPermission.askLocationPermission but Kostya do not like that
        lastPosition = crd;
    },
    e => {
        console.log(`Geo watch errro: ${e.message}`); // Ideally to show some error here for user but during initialization it always show timeput for the first time
    },
    {   
        enableHighAccuracy: true,
        timeout: 1000,
        maximumAge: 0,
    }
);

window.addEventListener("deviceorientation", (event) => { // this should be moved under the useGeoRequestPermission.askOrientationPermission but that is tricky and extensive testing needed
    const { alpha, beta, gamma } = event;
    currentOrientation = { alpha, beta, gamma };
});

export const useGeoRequestPermission = () => {
    useEffect(() => {
        const askOrientationPermission = async () => {
            // Support potential issue
            // In iOS 12.2 this feature can be found in Settings > Safari > Privacy & Security, and it’s disabled by default, manual activation required
            // in iOS 13 we can activate programmatically https://leemartin.dev/how-to-request-device-motion-and-orientation-permission-in-ios-13-74fc9d6cd140
            
            // Some idea how workarround it if the Prompt is annoing to users:
            // We actually get the infor insied 'addEventListener("deviceorientation" ...' as below
            // So if the is no info then we just triger this code postfactum
            if ((DeviceOrientationEvent as any).requestPermission && typeof (DeviceOrientationEvent as any).requestPermission === 'function') { // iOS 13+
                try {
                    const response = await (DeviceOrientationEvent as any).requestPermission();
                    if (response != 'granted') {
                        // it was denied previously, ideally we should inform user to go to settings and allow permission request for Safari/Chrome but the flow is not clear yet
                        console.log(`Orientation status is not granted`);
                    }
                } catch (e) {
                    console.error(e);
                    // alert(`Unexpected error during 'deviceorientation' permission request, please inform develoment team. ${e.error}`);
                }
            }
        }
        askOrientationPermission().catch(console.error);
    }, []);

    useEffect(() => {
        const askLocationPermission = async () => {
            const result = await navigator.permissions.query({name: 'geolocation'});
            // here expectation is that result.state === 'granted'
            if (result.state === 'denied') {
                // it was denied previously, ideally we should inform user to go to settings and allow permission request for Safari/Chrome but the flow is not clear yet
                console.log(`Geo status is denied`);
            } else if (result.state === 'prompt') {
                //  here we actually ask user for permission
                navigator.geolocation.getCurrentPosition(_ => {}); // just to trigger permission request
            }
        }

        askLocationPermission().catch();
    }, []);
    return {}
}

const blobReduce = new BlobReduce();

export interface IOnsiteAuditPhotoExt {
    record: EV.IOnsiteAuditPhoto;
    thumbUrl: string;
    fullUrl: string;
}

async function calculateMD5(file: File) {
    const buf = await file.arrayBuffer();
    const wordArray = CryptoJS.lib.WordArray.create(buf);
    return CryptoJS.MD5(wordArray).toString(CryptoJS.enc.Base64);
}

function usePhotos(auditFsid: string, photoCol: PhotoDbCol, owningObject: string, name = 'New') {
    const { api, env, log } = useAppCtx();
    const updateUtils = useMemo(() => new UpdateUtils(log), [log]);
    const photoMgr = useMemo(() => new PhotoManager(api, photoCol), [api, photoCol]);
    const [list, setList] = useState<IOnsiteAuditPhotoExt[]>([]);

    const cleanupUrls = () => {
        for (const photo of list) {
            URL.revokeObjectURL(photo.thumbUrl);
            URL.revokeObjectURL(photo.fullUrl);
        }
    }

    const pathBuilder = useMemo(() => new EV.OrgBasedCloudStoragePathBuilder(env.cfg.shared.cdn, api.org), [env, api]);

    useEffect(() => {
        if (photoCol) {
            setList([]); // Clean the list immediately as load takes time
            const unsubscribe = photoCol.subscribeAll(async res => {
                const locMap = await db.getMap(res.map(p => p.fsid));
                setList(res.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1)).map(record => {
                    const thumbUrl = URL.createObjectURL(new Blob([record.thumbBytes], { type: 'image/jpeg' }));
                    const local = locMap.get(record.fsid);
                    const fullUrl = local ? URL.createObjectURL(local.file) : pathBuilder.absoluteAuditImageUrls(auditFsid, record.fsid).download;
                    return { record, thumbUrl, fullUrl };
                }));
            });
            return () => {
                cleanupUrls();
                unsubscribe();
            }
        }
    }, [photoCol]);

    const add = async (file: File) => {
        if (file) {
            const thumb = await blobReduce.toBlob(file, { max: 306 });
            const buf = await thumb.arrayBuffer();
            const thumbBytes = new Uint8Array(buf);
            const md5 = await calculateMD5(file);
    
            const photo: Partial<EV.IOnsiteAuditPhoto> = {
                thumbBytes,
                md5,
                tags: []
            };
            if (lastPosition) {
                const { latitude, longitude } = lastPosition.coords;
                photo.pos = { lat: latitude, lng: longitude };
            }
            if (currentOrientation) {
                photo.orientation = currentOrientation;
            }
            const uid = EV.utils.str.generateString();
            await photoMgr.save(auditFsid, uid, file, photo);    
        }
    }

    const update = async (fsid: string, data: Partial<EV.IOnsiteAuditPhoto>) => updateUtils.updateAndToast({ fsid, ...data }, photoCol);

    const remove = (photo: EV.IOnsiteAuditPhoto) => photoMgr.remove(photo.fsid);

    const removeAll = () => photoMgr.removeAll();

    const confirmAndRemoveAll = async () => {
        const conf = await confirmations.confirmOrCancel({ title: `Are you sure you want to delete all photos from '${name}' ${owningObject}?`, text: `This action is final and the photo can't be recovered.` });
        if (!conf.isConfirmed) return;

        try {
            await removeAll();
            toast.success(`Successfully removed all photos from '${name}' ${owningObject}.`);
        }
        catch (err) {
            console.error(err);
            const msg = (err instanceof EV.BusinessError) ? err.message : `Unexpected error removing all photos from '${name}' ${owningObject}. Please try again in a moment.`;
            toast.error(msg);
        }
    }

    return { list, add, remove, update, removeAll, confirmAndRemoveAll };
}

export type IOnsiteAuditPhotoModel = ReturnType<typeof usePhotos>;

export function useLineItemPhotos(auditFsid: string, itm: EV.IOnsiteAuditLineItem) {
    const { fstore } = useAppCtx();
    const lineItemCol = useMemo(() => fstore.auditLineItem(auditFsid), [auditFsid]);
    const photoCol = useMemo(() => lineItemCol.photoCol(itm.fsid), [itm.fsid]);
    return usePhotos(auditFsid, photoCol, 'line item', itm.name);
}

export function useAreaPhotos(auditFsid: string, area: Partial<EV.IArea>) {
    const { fstore } = useAppCtx();
    const areaCol = useMemo(() => fstore.auditArea(auditFsid), [auditFsid]);
    const photoCol = useMemo(() => (area.fsid ? areaCol.photoCol(area.fsid) : undefined), [area.fsid]);
    const photosModel = usePhotos(auditFsid, photoCol, 'area', area.name);
    return area.fsid ? photosModel : undefined;
}

export interface IPhotoStatus extends IOnsiteAuditPhotoExt {
    colPath: string[];
    title: string;
    area: string;
    line?: string;
    local: ILocalPhoto;
    cloud?: ProgressStatus;
}

function withStats(photos: IPhotoStatus[]) {
    const total = photos.length;
    let local = 0;
    let forUpload = 0;
    let uploadInProgress = false;
    let allUploaded = true; 

    for (const status of photos) {
        if (status.local) {
            local++;
        }
        if (status.cloud != 'complete') {
            if (status.local) forUpload++;
            allUploaded = false;
        }
        if (status.cloud == 'progress') {
            uploadInProgress = true;
        }
    }

    return {
        photos,
        total,
        local,
        forUpload,
        uploadInProgress,
        allUploaded,
    }
}

export async function loadPhotoStatuses(api: Api, fstore: Fstore, env: ILocalEnv, auditFsid: string) {
    await fstore.waitPendingWrites();
    const photoStatuses: IPhotoStatus[] = [];
    const areaCol = fstore.auditArea(auditFsid);
    const lineItemCol = fstore.auditLineItem(auditFsid);
    const fileList: { uid: string, hash: string }[] = [];
    const forAudit = await db.getPhotosByAudit(auditFsid);
    const localMap = new Map(forAudit.map(p => [p.uid, p]));

    const areas = await areaCol.listAll(true);
    const areaMap = new Map(areas.map(a => [a.fsid, a]));
    const lineItems = await lineItemCol.listAll(true);

    const pathBuilder =  new EV.OrgBasedCloudStoragePathBuilder(env.cfg.shared.cdn, api.org);
    const addPhoto = (title: string, record: EV.IOnsiteAuditPhoto, colPath: string[], area: string) => {
        const thumbUrl = URL.createObjectURL(new Blob([record.thumbBytes], { type: 'image/jpeg' }));
        const local = localMap.get(record.fsid);
        const fullUrl = local ? URL.createObjectURL(local.file) : pathBuilder.absoluteAuditImageUrls(auditFsid, record.fsid).download;

        photoStatuses.push({ record, area, title, thumbUrl, fullUrl, local, colPath });
        fileList.push({ uid: record.fsid, hash: record.md5 });
    }

    const collectArea = async (fsid: string, name: string) => {
        const photoCol = areaCol.photoCol(fsid);
        const photos = await photoCol.listAll(true);
        for (const photo of photos) {
            addPhoto(name, photo, photoCol.colPath, name);
        }
    }

    const collectLine = async (fsid: string, areaFsid: string, name: string) => {
        const photoCol = lineItemCol.photoCol(fsid);
        const photos = await photoCol.listAll(true);
        for (const photo of photos) {
            const area = areaMap.get(areaFsid);
            const title = `${area.name} - ${name}`;
            addPhoto(title, photo, photoCol.colPath, area.name);
        }
    }

    await Promise.all(areas.map(area => collectArea(area.fsid, area.name)));

    const chunkSize = 20;
    let lineItemsChunk = [];
    for (const item of lineItems) {
        lineItemsChunk.push(item);
        if (lineItemsChunk.length >= chunkSize) {
            await Promise.all(lineItemsChunk.map(item => collectLine(item.fsid, item.areaFsid, item.name)));
            lineItemsChunk = [];
        }
    }
    if (lineItemsChunk.length >= 0) {
        await Promise.all(lineItemsChunk.map(item => collectLine(item.fsid, item.areaFsid, item.name)));
    }

    const { statuses } = await api.checkPhotoServerStatuses(auditFsid, fileList);
    
    for (const status of photoStatuses) {
        status.cloud = statuses[status.record.fsid] ? 'complete' : 'waiting';
        status.local = localMap.get(status.record.fsid);
    }

    return withStats(photoStatuses);
}

export interface IAuditPhotoStatusesModel {
    photos?: IPhotoStatus[];
    total?: number;
    local?: number;
    forUpload?: number;
    uploadInProgress?: boolean;
    allUploaded?: boolean;
    error?: boolean;
}

export function useAuditPhotoStatuses(auditFsid: string) {
    const { api, fstore, env, log } = useAppCtx();
    const updateUtils = useMemo(() => new UpdateUtils(log), [log]);
    const [photoStatuses, setPhotoStatuses] = useState<IAuditPhotoStatusesModel>();
    const [downloadingZip, setDownloadingZip] = useState(false);
    const refresh = () => {
        setPhotoStatuses(undefined);
        loadPhotoStatuses(api, fstore, env, auditFsid)
            .then(setPhotoStatuses)
            .catch(err => {
                console.error(err);
                setPhotoStatuses({ error: true });
            });
    }

    useEffect(() => {
        if (auditFsid) {
            refresh();
        }
    }, [auditFsid]);

    const patchPhotoStatus = (photo: IPhotoStatus, patch: Partial<IPhotoStatus>) => setPhotoStatuses(prevState => {
        const updPhphotos = prevState.photos.map(p => (p.record.fsid == photo.record.fsid ? {
                ...p,
                ...patch,
            } : p)
        );
        return withStats(updPhphotos);
    });
    

    const updatePhoto = (photo: IPhotoStatus, data: Partial<EV.IOnsiteAuditPhoto>) => {
        updateUtils.updateAndToast({ 
                fsid: photo.record.fsid, 
                ...data 
            }, 
            fstore.photoCol(photo.colPath)
        );
        patchPhotoStatus(photo, {
            record: {
                ...photo.record,
                ...data,
            }
        });
    }

    const uploadOne = (photo: IPhotoStatus) => {
        patchPhotoStatus(photo, { cloud: 'progress'});
        return api
            .uploadPhoto(photo.local)
            .then(() => patchPhotoStatus(photo, { cloud: 'complete'}))
            .catch(() => patchPhotoStatus(photo, { cloud: 'failed'}))
    }

    const addFile = (folder: JSZip, photo: IPhotoStatus) => {
        const ext = EV.utils.mimes[photo.local.file.type] ?? '.jpeg';
        folder.file(`${photo.local.uid}.${ext}`, photo.local.file);
    }

    const exportAsZip = async () => {
        setDownloadingZip(true);
        const zip = new JSZip();
        const folders = new Map<string, { area: JSZip, items: Map<string, JSZip> }>();

        for (const photo of photoStatuses.photos) {
            if (!photo.local) continue;
            let folder = folders.get(photo.area);
            if (!folder) {
                folder = { area: zip.folder(photo.area), items: new Map() };
                folders.set(photo.area, folder);
            }
            if (photo.line) {
                let item = folder.items.get(photo.line);
                if (!item) {
                    item = folder.area.folder(photo.line);
                    folder.items.set(photo.line, item);
                }
                addFile(item, photo);
            }
            else {
                addFile(folder.area, photo);
            }
        }
        // Generate the zip file
        const zipBlob = await zip.generateAsync({ type: 'blob' });
        // Save the zip file
        saveAs(zipBlob, `photos-${auditFsid}.zip`);
        setDownloadingZip(false);
    }

    return { photoStatuses, refresh, updatePhoto, uploadOne, exportAsZip, downloadingZip };    
}

