import { Fstore, PhotoDbCol } from "fb-web";
import { Api } from "model/api";
import { EV } from 'enerx-shared';
import { db } from "./db";
import { ILocalPhoto } from "./types";

export class PhotoManager {
    constructor(private api: Api, private photoCol: PhotoDbCol) {}

    async save(auditFsid: string, uid: string, file: File, photo: Partial<EV.IOnsiteAuditPhoto>) {
        const loc: ILocalPhoto = {
            auditFsid,
            uid,
            file,
            uploaded: false,
        }
        await db.put(loc);
        await this.photoCol.setDoc(uid, photo);
        if (navigator.onLine) {
            this.api.uploadPhoto(loc).catch(() => console.error('Cannot upload photo - will do prior complete'));
        }
    }

    async remove(uid: string) {
        await this.photoCol.remove(uid);
        const loc = await db.get(uid);
        if (loc) {
            await db.remove(uid);
            if (navigator.onLine) {
                this.api.removePhoto(loc.auditFsid, uid).catch(() => console.error('Cannot remove photo - will do after complete'));
            }    
        }
    }

    async removeAll() {
        const all = await this.photoCol.listAll();
        for (const { fsid } of all) {
            await this.remove(fsid);
        }
    }
}

export class PhotoCleanupManager {
    readonly stats: {
        list: {
            auditFsid: string,
            audit: EV.IOnsiteAudit,
            count: number,
        }[];
        completed: {
            audts: number;
            photos: number;
        }
    } = {
        list: [],
        completed: {
            audts: 0,
            photos: 0,
        }
    }

    constructor(private fstore: Fstore) {}

    async refreshStats() {
        const photos = await db.countsByAudit();
        const audits = await this.fstore.audit.listAll();
        const auditMap = new Map(audits.map(a => [a.fsid, a]));
        for (const [auditFsid, count] of Array.from(photos.entries())) {
            const audit = auditMap.get(auditFsid);
            this.stats.list.push({ auditFsid, audit, count });
            if (!audit || audit.status != 'active') {
                this.stats.completed.audts++;
                this.stats.completed.photos += count;
            }
        }

        return this.stats;
    }

    async cleanup() {
        for (const { audit, auditFsid } of this.stats.list) {
            if (!audit || audit.status != 'active') {
                await db.removeInAudit(auditFsid);
            }
        }
    }

    async cleanupAudit(auditFsid: string) {
        await db.removeInAudit(auditFsid);
    }
}