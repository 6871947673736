import { EV } from 'enerx-shared';
import { model } from 'model';
import { useState } from 'react';
import { Alert, Button, Form, Input, Label, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink } from 'reactstrap'
import { DefaultsEditor } from './DefaultsEditor';
import { useAreaPhotos, useGeoRequestPermission } from 'model/photos/hooks';
import { AttachedPhotos } from 'views/common/AttachedPhotos';

export const AddEditArea = ({ toggle, origin }: { toggle: () => void, origin: EV.IArea }) => {
    const { saveArea, master, activeAudit } = model.useAuditCtx();
    const newArea = () => ({
        name: '',
        burnHours: activeAudit?.burnHours,
        fixtureHeight: activeAudit?.fixtureHeight,
        ceilingHeight: activeAudit?.ceilingHeight,
        voltage: activeAudit?.voltage,
        ac: activeAudit?.ac,
    })

    const [area, setArea] = useState<Partial<EV.IArea>>(origin ?? newArea());
    const defaultsModel = model.useDefaults(area, { heated: true });
    const [mode, setMode] = useState<'props' | 'photos'>('props');

    const photosModel = useAreaPhotos(activeAudit.fsid, area);
    useGeoRequestPermission();

    const save = (e) => {
        e.preventDefault();
        console.log('saving area', area);
        saveArea({...area, ...defaultsModel.asPatch() });
        const action = e.nativeEvent.submitter.value;
        if (action == 'save-add') {
            defaultsModel.reset();
            setArea(newArea());
        }
        if (action == 'save-close') {
            toggle();
        }
    }

    return (
    <>
        <Modal isOpen={true} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                <Nav tabs>
                    <NavItem className='ps-0 pe-0 m-0'>
                        <NavLink className='ps-50 pe-50 m-0' active={mode == 'props'} onClick={() => setMode('props')}>
                            Properties
                        </NavLink>
                    </NavItem> 
                    <NavItem className='ps-0 pe-0 m-0'>
                        <NavLink className='ps-50 pe-50 m-0' active={mode == 'photos'} onClick={() => setMode('photos')}>
                            Photos
                        </NavLink>
                    </NavItem>
                </Nav>
            </ModalHeader>
            <ModalBody>
            {
                mode == 'props' && (
                    <Form onSubmit={save}>
                        <div className="row">
                            <div className='col-12'>
                                <Alert color='primary'>
                                    <div className='alert-body'>
                                        Values entered below will autofill new Line Items for this Area. Leave fields blank to avoid setting Area defaults.
                                    </div>
                                </Alert>
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-lg-6 col-md-12 mb-1">
                                <Label for='name'>Name</Label>
                                <Input id='name' value={area.name} onChange={(e) => setArea({...area, name: e.target.value})} type='text' required /> 
                            </div>
                            <div className="form-group col-lg-6 col-md-12 mb-1">
                                <Label for='areaType'>Type:</Label>
                                <Input id='areaType' type='select' value={area.areaType ?? ''} onChange={(e) => setArea({...area, areaType: e.target.value})} required>
                                    <option></option>
                                    {
                                        master.areaType?.map(e => <option key={e.id} value={e.id}>{e.label}</option>)
                                    }
                                </Input>
                            </div>
                            <DefaultsEditor model={defaultsModel} />
                        </div>
        
                        <div className="text-end">
                            <Button color="secondary" className='mt-1' onClick={toggle}>Cancel</Button>
                            <Button type="submit" name="action" value="save-add" color="dark" className='ms-1 mt-1'>Save & Add New Area</Button>
                            <Button type="submit" name="action" value="save-close" color="primary" className='ms-1 mt-1'>Save & Close</Button>
                        </div>
                    </Form>
                )
            }    
            {
                mode == 'photos' && photosModel && (
                    <AttachedPhotos mode='photos' model={photosModel} size='std' />
                )
            }
            </ModalBody>
        </Modal>
    </>);
}