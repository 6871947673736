import { IOnsiteAuditPhotoExt } from "model/photos"
import { Copy, MapPin } from "react-feather";
import { Button, Col, Form, Input, Label, Modal, ModalBody, ModalHeader } from "reactstrap"
import CreatableSelect from 'react-select/creatable';
import { KeyboardEventHandler, useState } from "react";
import { EV } from 'enerx-shared';

export const PhotoInfoModal = ({ photo, toggle, update }: { photo: IOnsiteAuditPhotoExt, toggle: () => void, update: (fdto: Partial<EV.IOnsiteAuditPhoto>) => any }) => {
    const coords = photo.record.pos ? `${photo.record.pos.lat},${photo.record.pos.lng}` : '';
    const [notes, setNotes] = useState(photo.record.notes ?? '');
    const [tagInputValue, setTagInputValue] = useState('');
    const [tagValue, setTagValue] = useState<readonly { value: string; label: string; }[]>(photo.record.tags?.map(e => ({ value: e, label: e })) ?? []);
    const handleKeyDown: KeyboardEventHandler = (event) => {
        if (!tagInputValue) return;
        switch (event.key) {
          case 'Enter':
          case 'Tab':
            setTagValue([...tagValue, { value: tagInputValue, label: tagInputValue }]);
            setTagInputValue('');
            event.preventDefault();
        }
    };    

    return (
        <Modal isOpen={true} toggle={toggle} size="sm">
            <ModalHeader tag='h4' toggle={toggle}>Photo Information</ModalHeader>
            <ModalBody>
                <Form onSubmit={e => {
                    e.preventDefault();
                    update({ notes, tags: tagValue.map(e => e.value) });
                    toggle();
                }}>
                {
                    (photo.record.pos && photo.record.pos.lat && photo.record.pos.lng) && <div className="form-group mb-1 row">
                        <Col sm={6} xs={12}>
                            {coords}
                        </Col>
                        <Col sm={6} xs={12} className="text-end">
                            <Button color='dark' size='sm' onClick={() => navigator.clipboard.writeText(coords)}><Copy /></Button>
                            <a 
                                href={`https://www.google.com/maps?q=${coords}`}
                                className='btn btn-icon btn-primary btn-sm ms-1' 
                                target="_blank"
                            >
                                <MapPin />
                            </a>
                        </Col>                        
                    </div>
                }
                    <div className="form-group mb-1">
                        <Label for='notes'>Notes</Label>
                        <Input id='notes' value={notes} type='textarea' style={{ height: '100px' }} onChange={e => setNotes(e.target.value)} /> 
                    </div>
                    <div className="form-group mb-1">
                        <Label for='tags'>Tags</Label>
                        <CreatableSelect
                            components={{ DropdownIndicator: null }}
                            inputValue={tagInputValue}
                            isClearable
                            isMulti
                            menuIsOpen={false}
                            onChange={(newValue) => setTagValue(newValue)}
                            onInputChange={setTagInputValue}
                            onKeyDown={handleKeyDown}
                            placeholder="Type and press Enter to add tags"
                            value={tagValue}
                        />
                    </div>
                    <div className="text-end">
                        <Button color="secondary" className='mt-1' onClick={toggle}>Cancel</Button>
                        <Button type="submit" color="primary" className='ms-1 mt-1'>Save & Close</Button>
                    </div>
               </Form>
            </ModalBody>
        </Modal>
    )
}