import { EV } from 'enerx-shared'
import { Col, Row } from 'reactstrap';
import { formInput, UseLinePropertiesFilter } from './hooks/lineItem.hooks';
import './line.item.scss'
import { useUpdateReductionFromControl } from './hooks/useUpdateReductionFromControl';
import { useAutomateExistingTypeNONE } from './hooks/useAutomateExistingTypeNONE';
import { model } from 'model';

export const LineItemProperties = ({ lineItem, properties, useLinePropertiesFilter: filter }: {lineItem?: EV.IOnsiteAuditLineItem, properties: formInput[], useLinePropertiesFilter: UseLinePropertiesFilter}) => {
    const { auth } = model.useAppCtx();
    useUpdateReductionFromControl(properties, lineItem?.existingControl);
    useAutomateExistingTypeNONE(properties);

    const orderedLineFields = auth.features.support.orderPatternLineItemProperties;    
    const sortAsPattern = (a: {field: string}, b: {field: string}) => (orderedLineFields.length > 0 ? orderedLineFields.indexOf(a.field) - orderedLineFields.indexOf(b.field) : 0);

    return (
        <>
            {filter.Component}
            <hr/>

            <Row key='row'>
                {filter.applyFilters(properties, auth.features)    
                    .filter(p => p.field != 'notes')
                    .sort(sortAsPattern)    
                    .map(p => <Col key={p.field} xs={12} md={6}>
                        {p.Component}
                    </Col>
                )}
            </Row>
        </>
    )
}
