import { EV } from 'enerx-shared';
import { Fstore, ICfg } from "fb-web";

export class Log extends EV.EventLog {
    private level: number;
    private email: string;

    constructor(user: EV.IUserEssentials, private fstore: Fstore, private cfg: ICfg) {
        super();
        /*fstore.org.subscribeOrgData(org => {
            const logCfd = org.settings?.logCfg;
            if (user && logCfd) {
                this.email = user.email;
                const usrCfd = logCfd.users && logCfd.users[this.email];
                const deviceCfg = logCfd.devices && logCfd.devices[cfg.device];
                this.level = EV.EventLog.severityCoef(deviceCfg || usrCfd || logCfd.default);    
            }
            else {
                this.email = null;
                this.level = EV.EventLog.severityCoef(EV.EventSeverity.Error);
            }    
        });*/
    }

    logEvent(severity: EV.EventSeverity, message: string, data: any, labels: Record<string, string>) {
        // [KS] audit logging is disabled for now as it never worked as expected
        console.log(severity, message, data, labels);

        /*if (EV.EventLog.severityCoef(severity) < this.level) return;

        try {
            const payload: Partial<EV.IFrontendLogEntry> = {
                severity,
                message,
                data: EV.EventLog.sanitize(data),
                labels: labels === undefined ? null : labels,
                email: this.email,
                agent: navigator.userAgent,
                device: this.cfg.device,            
            }

            this.fstore.logEntry.insert(payload);
            console.log(message, { data, labels });
        }
        catch (err) {
            console.error('Cannot insert log message', err, { message, data, labels });
        }*/
    }
}
