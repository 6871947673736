import { FormEvent } from "react";

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj: object) => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = (num: number) => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = (html: string) => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = (date: Date) => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

// ** React Select Theme Colors
export const selectThemeColors = (theme: any) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})

const formSubmitHandler = (delegate: Function) => (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    delegate();
}

const invNum = (n?: number | null) => n == null || Number.isNaN(n);

const displayNanAsEmpty = (n?: number | null) => (invNum(n) ? '' : n);
const displayNanAsEmptyDecimal = (n?: number | null, decimal: number = 2) => (invNum(n) ? '' : n!.toFixed(decimal));
const nanAsZero = (n?: number | null) => (invNum(n) ? 0 : n!);

export const frontUtils = {
    formSubmitHandler,
    displayNanAsEmpty,
    displayNanAsEmptyDecimal,
    nanAsZero,
    invNum,
}
