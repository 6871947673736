import { EV } from 'enerx-shared';
import { model } from 'model';
import { useAreaPicker, useFixtureTypeInputPicker, useInputCheckbox, useInputExisting, useInputMultilineText, useInputMultiSelect, useInputNumber, useInputPicker, useInputText } from './lineItem.hooks';

export const useLineProperties = (lineItem: EV.IOnsiteAuditLineItem, setLineItem: (a: EV.IOnsiteAuditLineItem) => void) => {
    const audit = model.useAuditCtx();
    const { auth } = model.useAppCtx();

    const accessComplexity = [{id: 'easy', label: 'Easy', status: EV.MasterStatus.Active}, {id: 'difficult', label: 'Difficult', status: EV.MasterStatus.Active}];
    const auditAttributes = auth.features.auditAttributes;
    if (!Array.isArray(lineItem.auditAttributes)) {
        lineItem.auditAttributes = [];
    }

    const filterDetailsByExistingType = () => {
        const detailIds: string[] = audit.master.existingFType?.find(e => e.id === lineItem.existingFType)?.details;
        return detailIds ? audit.master.fixtureDetail.filter(d => detailIds.includes(d.id) || d.id === lineItem.fixtureDetail) : [];
    }

    // NOTE: exact list and order on UI is defined via 'orderPatternLineItemProperties'
    const properties = [
        useAreaPicker<EV.IOnsiteAuditLineItem>(lineItem, audit.activeAuditAreas, setLineItem),
        useInputText<EV.IOnsiteAuditLineItem>('name', 'Line Name', lineItem, setLineItem, true),
        useFixtureTypeInputPicker<EV.IOnsiteAuditLineItem>('existingFType', 'Fixture Type', lineItem, audit.master.existingFType, setLineItem, true),
        useInputExisting('Existing Fixture', lineItem, audit.activeAuditExistings, setLineItem, true),
        useInputPicker<EV.IOnsiteAuditLineItem>('fixtureDetail', 'Detail', lineItem, filterDetailsByExistingType(), setLineItem, true),
        useInputPicker<EV.IOnsiteAuditLineItem>('lampDetail', 'Lamp Detail', lineItem, audit.master.lampDetail, setLineItem, true),
        useInputNumber<EV.IOnsiteAuditLineItem>('qty', `Qty`, lineItem, setLineItem, 'float', true),
        useInputPicker<EV.IOnsiteAuditLineItem>('powerMethod', 'Power Method', lineItem, audit.master.powerMethod, setLineItem, false),
        useInputPicker<EV.IOnsiteAuditLineItem>('mountingMethod', 'Mounting Method', lineItem, audit.master.mountingMethod, setLineItem, false),
        useInputNumber<EV.IOnsiteAuditLineItem>('numLampOut', `# of Lamps Out`, lineItem, setLineItem, 'int'),
        useInputNumber<EV.IOnsiteAuditLineItem>('numFixtureNotWorking', `# of Fixtures not working`, lineItem, setLineItem, 'int'),
        useInputPicker<EV.IOnsiteAuditLineItem>('difficultyFactor', 'Difficulty Factor', lineItem, audit.master.difficultyFactor, setLineItem),
        useInputPicker<EV.IOnsiteAuditLineItem>('proposedAction', 'Proposed Action', lineItem, audit.master.proposedAction, setLineItem),
        useInputNumber<EV.IOnsiteAuditLineItem>('burnHours', `Burn Hours`, lineItem, setLineItem, 'float', false),
        useInputPicker<EV.IOnsiteAuditLineItem>('voltage', 'Voltage', lineItem, audit.master.voltage, setLineItem, true),
        useInputPicker<EV.IOnsiteAuditLineItem>('ac', 'A/C', lineItem, audit.master.ac, setLineItem, auth.features.support.auditAcRequired),
        useInputPicker<EV.IOnsiteAuditLineItem>('existingControl', 'Existing Sensor', lineItem, audit.master.existingControl, setLineItem),
        useInputNumber<EV.IOnsiteAuditLineItem>('reduction', 'Reduction', lineItem, setLineItem),
        useInputPicker<EV.IOnsiteAuditLineItem>('ceilingType', 'Ceiling Type', lineItem, audit.master.ceilingType, setLineItem),
        useInputNumber<EV.IOnsiteAuditLineItem>('gableHeight', `Gable Height`, lineItem, setLineItem),
        useInputNumber<EV.IOnsiteAuditLineItem>('ceilingHeight', `Ceiling Height`, lineItem, setLineItem, 'float', false),
        useInputNumber<EV.IOnsiteAuditLineItem>('fixtureHeight', `Fixture Height`, lineItem, setLineItem, 'float', false),
        useInputPicker<EV.IOnsiteAuditLineItem>('specialEquipment', 'Special Equipment', lineItem, audit.master.specialEquipment, setLineItem),
        useInputText<EV.IOnsiteAuditLineItem>('fcRange', `FC Range`, lineItem, setLineItem),
        useInputNumber<EV.IOnsiteAuditLineItem>('fcAverage', `FC Average`, lineItem, setLineItem),
        useInputNumber<EV.IOnsiteAuditLineItem>('fcLow', `FC Low`, lineItem, setLineItem),
        useInputNumber<EV.IOnsiteAuditLineItem>('fcHigh', `FC High`, lineItem, setLineItem),
        useInputPicker<EV.IOnsiteAuditLineItem>('poleWidth', 'Pole Width', lineItem, audit.master.poleSize, setLineItem),
        useInputPicker<EV.IOnsiteAuditLineItem>('poleType', 'Pole Type', lineItem, audit.master.poleType, setLineItem),
        useInputPicker<EV.IOnsiteAuditLineItem>('poleColor', 'Pole Color', lineItem, audit.master.poleColor, setLineItem),
        useInputPicker<EV.IOnsiteAuditLineItem>('lensType', 'Lens Type', lineItem, audit.master.lensType, setLineItem),
        // useInputCheckbox<EV.IOnsiteAuditLineItem>('shatterProof', `Shatter Proof`, lineItem, setLineItem),
        // useInputCheckbox<EV.IOnsiteAuditLineItem>('explosionProof', `Explosion Proof`, lineItem, setLineItem),
        useInputCheckbox<EV.IOnsiteAuditLineItem>('unconfirmed', 'Unconfirmed', lineItem, setLineItem),
        // useInputCheckbox<EV.IOnsiteAuditLineItem>('emergencyBackup', 'Emergency Back-up', lineItem, setLineItem),
        useInputNumber<EV.IOnsiteAuditLineItem>('addMatCost', `Add'l Mat Cost ($)`, lineItem, setLineItem),
        useInputNumber<EV.IOnsiteAuditLineItem>('addMatBuffer', `Add'l Mat Buffer (%)`, lineItem, setLineItem),
        useInputNumber<EV.IOnsiteAuditLineItem>('addLaborCost', `Add'l Labor Cost ($)`, lineItem, setLineItem),
        useInputText<EV.IOnsiteAuditLineItem>('addCostNotes', `Add'l Cost Notes`, lineItem, setLineItem),
        useInputMultilineText<EV.IOnsiteAuditLineItem>('notes', `Notes`, lineItem, setLineItem),
        // useInputPicker<EV.IOnsiteAuditLineItem>('accessComplexity', 'Access Complexity', lineItem, audit.master.accessComplexity, setLineItem),
        useInputPicker<EV.IOnsiteAuditLineItem>('accessComplexity', 'Access Complexity', lineItem, accessComplexity, setLineItem),
        useInputMultiSelect<EV.IOnsiteAuditLineItem>('auditAttributes', 'Audit Attributes', lineItem, auditAttributes, setLineItem),

    ];

    return {properties}
}
