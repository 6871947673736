import { IOnsiteAuditModel } from "./audit";
import { createContext, useContext } from "react";
import { IAppModel } from "./app";

export const AppCtx = createContext<IAppModel>(null);
export const AuditCtx = createContext<IOnsiteAuditModel>(null);

export const useAppCtx = () => useContext(AppCtx);
export const useAuditCtx = () => useContext(AuditCtx);

