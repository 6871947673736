import { useAuditCtx } from "model/ctx";
import { useAuditPhotoStatuses } from "model/photos/hooks";
import { Alert, Button, Col, Row, Spinner } from "reactstrap";
import { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { ArrowLeft, DownloadCloud, StopCircle, UploadCloud } from "react-feather";
import { RenderPhoto } from "views/common/RenderPhoto";
import { TheSwal, confirmations, defaultConfirmStyle } from "utility/confirmations";

export const ManageAuditPhotos = () => {
    const { activeAudit } = useAuditCtx();
    const { photoStatuses, refresh, updatePhoto, uploadOne, exportAsZip, downloadingZip } = useAuditPhotoStatuses(activeAudit?.fsid);
    const history = useHistory();
    const hardStop = useRef<boolean>(false);

    useEffect(() => {
    if (photoStatuses?.error) {
            TheSwal.fire({
                ...defaultConfirmStyle,
                text: 'Error loading photo statuses. Please make sure you are online and click Retry button. Or click Cancel to return to the audit.',
                title: 'Error',
                confirmButtonText: 'Retry',
                cancelButtonText: 'Cancel',            
            }).then(res => {
                if (res.isConfirmed) {
                    refresh();
                }
                else {
                    history.push(`/audit/${activeAudit.fsid}`);
                }
            
            })
        }
    }, [photoStatuses]);

    if (!photoStatuses) return <Spinner color="info" />;
    if (photoStatuses.error) return <></>;

    const uploadAll = async () => {
        if (!activeAudit || !navigator.onLine) {
            confirmations.errorNotification({
                text: 'You need to be online to upload photos.'
            });
            return;
        }
        for (const photo of photoStatuses.photos) {
            if (hardStop.current) break;
            if (photo.cloud == 'complete') continue;
            await uploadOne(photo);
        }
    }

    return <>
        <Row>
            <Col sm={6}>
                <h2>Manage Audit Photos</h2>
            </Col>
            <Col sm={6} className='text-end'>
            {
                !photoStatuses.uploadInProgress && !photoStatuses.allUploaded && <Button color='primary' onClick={uploadAll} size='sm' className="me-1"><UploadCloud size={16}/> Start Upload</Button>
            }
            {
                photoStatuses.uploadInProgress && <Button color='danger' onClick={uploadAll} size='sm' className="me-1"><StopCircle size={16}/> Cancel Upload</Button>
            }
            {
                photoStatuses.local > 0 && <Button color='dark' disabled={downloadingZip} onClick={exportAsZip} size='sm' className="me-1"><DownloadCloud size={16} /> Download Zip</Button>
            }
                <Button color='primary' disabled={photoStatuses.uploadInProgress} outline onClick={() => history.push(`/audit/${activeAudit.fsid}`)} size='sm'><ArrowLeft size={16}/>Back to Audit</Button>
            </Col>
        </Row>
        <Row>
            <Col className="m-2">
                <Alert color='primary'>
                    <div className='alert-body'>
                    {
                        photoStatuses.total == 0 ? <>
                            This audit doesn't have photos.
                        </> : (photoStatuses.forUpload == 0 ? <>
                            This audit has <b>{photoStatuses.total}</b> photos totally. 
                            All of those that available on your device are uploaded to the cloud. 
                        </> : <>
                            This audit has <b>{photoStatuses.total}</b> photos totally. 
                            <b> {photoStatuses.forUpload}</b> of them available on this device but not yet uploaded to the cloud.
                            Use [Start Upload] button to upload them.
                            If you experience issues uploading, please use [Download] button to save them as a zip file.
                        </>)
                    }
                    </div>
                </Alert>
            </Col>
        </Row>
        <Row>
        <Col>
        {
            photoStatuses.photos.map((photo) => (
                <div className="d-inline-block mb-1" style={{width: '250px', verticalAlign: 'top'}} key={photo.record.fsid}>
                    <RenderPhoto 
                        photo={photo}  
                        upload={{
                            status: photo.cloud,
                            onRetry: () => uploadOne(photo)
                        }} 
                        update={data => updatePhoto(photo, data)}
                    />
                </div>
            ))
        }
            </Col>
        </Row>
    </>;

}