import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import SwiperCore, { Pagination, Navigation, Grid, EffectFade, EffectCoverflow } from 'swiper';
import '@core/scss/base/swiper.scss';

SwiperCore.use([Navigation, Grid, Pagination, EffectFade, EffectCoverflow]);

const baseParams = {
    spaceBetween: 30,
    slidesPerView: 4,
    pagination: {
        clickable: true,
    },
}

const stdBreakpoints = {
    640: {
        slidesPerView: 2,
        spaceBetween: 20
      },
      320: {
        slidesPerView: 1,
        spaceBetween: 10
      }
}

const stdParams = {
    ...baseParams,
    breakpoints: stdBreakpoints
}

const largeParams = {
    ...baseParams,
    breakpoints: {
        2500: {
            slidesPerView: 5,
            spaceBetween: 30
        },
        1440: {
            slidesPerView: 4,
            spaceBetween: 30
        },
        1024: {
            slidesPerView: 3,
            spaceBetween: 30
        },
        768: {
            slidesPerView: 3,
            spaceBetween: 30
        },
        ...stdBreakpoints,
    }
}

export const PhotoSwiper = ({ name, slides, size = 'lg'}: { name?: string, slides: JSX.Element[], size?: 'lg' | 'std' }) => {
    let i = 0;
    const swiperParams = size === 'lg' ? largeParams : stdParams;
    return <>
    {slides?.length > 0 && <>
        {name && <h3 className='mb-2'>{name}</h3>}
        <Swiper {...swiperParams}>
                {slides.map(slide => (
                    <SwiperSlide key={++i}>
                        {slide}
                    </SwiperSlide>
                ))}
        </Swiper></>}
    </> 
}
