import { EV, EE } from 'enerx-shared';
import { FirebaseAuthentication, SignInResult } from '@capacitor-firebase/authentication';
import { AuthCredential, FacebookAuthProvider } from 'firebase/auth';
import { EEAuth } from "fb-web";
import { Capacitor } from "@capacitor/core";


export class NativeAuth extends EEAuth {

    async providerSignIn(providerId: EV.SupportedProviders) {
        if (!Capacitor.isNativePlatform()) {
            return super.providerSignIn(providerId);
        }
        
        this.setStatus('loading');
        if (providerId == 'microsoft.com') {
            let signRes;
            try {
                signRes = await FirebaseAuthentication.signInWithMicrosoft();
            } catch (e) {
                console.log(e);
                if (e.code == 'account-exists-with-different-credential') {
                    this.setStatus('provider-not-linked');
                } else {
                    this.setStatus('unknown-error');
                }
                return;
            }
            const res = await EEAuth.callEnerXLogin({ msAccessToken: signRes.credential.accessToken }, this.env);
            if (res.status < 300) {
                const { auth, fbToken } = res.data as { auth: EE.Auth, fbToken: string };
                await this.fb.signInWithCustomToken(fbToken);
                this.authenticated(auth);
            }
            else if (res.status == 401) {
                this.setStatus('invalid-cred');
            }
            else {
                console.error(res);
                this.setStatus('unknown-error');
            }
        }
        else {
            await this.firebaseLogin(this.nonMsLogin(providerId));
        }
    }


    private async nonMsLogin(providerId: EV.SupportedProviders) {
        let signRes: SignInResult;
        let authCred: AuthCredential;
        switch (providerId) {
            case 'google.com':
                signRes = await FirebaseAuthentication.signInWithGoogle();
                authCred = this.fb.providers['google.com'].credential(signRes.credential);
                return this.fb.signInWithCredential(authCred);
            case 'facebook.com':
                signRes = await FirebaseAuthentication.signInWithFacebook();
                authCred = FacebookAuthProvider.credential(signRes.credential.accessToken);
                return this.fb.signInWithCredential(authCred);
            case 'apple.com':
                signRes = await FirebaseAuthentication.signInWithApple({skipNativeAuth:true});
                authCred = this.fb.providers['apple.com'].credential({idToken:signRes.credential.idToken, rawNonce:signRes.credential.nonce});
                return this.fb.signInWithCredential(authCred);
            default:
                throw new Error(`Provider ${providerId} not supported`);
        }
    }

}