import { EV } from 'enerx-shared';
import { model } from "model";
import { useEffect, useMemo, useState } from "react";
import { Menu, MenuItem, Typeahead } from "react-bootstrap-typeahead";
import { useHistory } from "react-router-dom";
import { Button, Form, Input, Label, Modal, ModalBody, ModalHeader } from "reactstrap";
import ReactInputMask from "react-input-mask";

const dtFmt = (d: number | Date) => new Date(d).toISOString().substring(0, 10);

function renderSfdcOpportunity(opp: EV.IOrgEEOpportunity) {
    if (!opp) return '';
    // WattLogic often name the opportunity as city, state - so we don't want repeating if it is so    
    const set = new Set<string>();
    set.add(opp.code);
    set.add(opp.accName);
    set.add(`${opp.address?.city}, ${opp.address?.state}`);
    set.add(opp.name);
    return Array.from(set).join(' - ');
} 

export const AddEditAuditForm = ({ original, toggle }: { original?: EV.IOnsiteAudit, toggle: () => void}) => {
    const history = useHistory();
    const { auth } = model.useAppCtx();
    const { saveAudit, master, userList, utilities: srcut, sfdcOpps } = model.useAuditCtx();
    const utilities = useMemo(() => new EV.ElectricalUtilities(srcut), [srcut]);
    const [name, setName] = useState(original?.name ?? '');
    const [phone, setPhone] = useState(original?.phone ?? '');
    const [email, setEmail] = useState(original?.email ?? '');
    const [projectName, setProjectName] = useState(original?.projectName ?? '');
    const [utilityId, setUtilityId] = useState(original?.utilityId ?? null);
    const [customer, setCustomer] = useState(original?.customer ?? '');
    const [city, setCity] = useState(original?.address.city ?? '');
    const [state, setState] = useState(original?.address.state ?? '');
    const [street, setStreet] = useState(original?.address.street ?? '');
    const [street2, setStreet2] = useState(original?.address.street2 ?? '');
    const [zip, setZip] = useState(original?.address.zip ?? '');
    const [facilityNotes, setFacilityNotes] = useState(original?.facilityNotes ?? '');
    const [facilityType, setFacilityType] = useState(original?.facilityType ?? '');
    const [auditorFsid, setAuditorFsid] = useState(original?.auditorFsid ?? userList.find(u => u.email == auth.user.email)?.fsid ?? '');
    const [auditDate, setAuditDate] = useState(original?.auditDate ? dtFmt(original.auditDate!) : dtFmt(new Date()));
    const [selectedOpp, setSelectedOpp] = useState<EV.IOrgEEOpportunity>();

    useEffect(() => {
        if (selectedOpp) {
            console.log(selectedOpp)
            setName(selectedOpp.auditContact ? selectedOpp.auditContact.name : '');
            setPhone(selectedOpp.auditContact ? selectedOpp.auditContact.phone : '');
            setEmail(selectedOpp.auditContact ? selectedOpp.auditContact.email : '');
            setProjectName(selectedOpp.name ?? '');
            setCustomer(selectedOpp.accName ?? '');
            setCity(selectedOpp.address?.city ?? '');
            setState(selectedOpp.address?.state ?? '');
            setStreet(selectedOpp.address?.street ?? '');
            setStreet2(selectedOpp.address?.street2 ?? '');
            setZip(selectedOpp.address?.zip ?? '');
            setUtilityId(selectedOpp.utilityId);
        }
    }, [selectedOpp]);

    const sfdc = auth.features.support.opportunityCreation == 'sfdc';

    return (
        <Modal isOpen={true} toggle={toggle} style={{paddingTop:'50px'}} size='lg'>
            <ModalHeader toggle={toggle}>
                { original ? `Edit Audit: ${original.name}` : 'Add New Audit'}
            </ModalHeader>
            <ModalBody>
                <Form onSubmit={async (e) => {
                    e.preventDefault();
                    const newId = await saveAudit({ 
                        fsid: original?.fsid,
                        projectName,
                        customer,
                        address:{
                            city,
                            state,  
                            street,
                            street2,
                            zip,
                        },
                        facilityNotes,
                        facilityType,
                        name,
                        phone,
                        email,
                        utilityId,
                        auditorFsid,
                        auditDate: new Date(auditDate).getTime(),
                        status: EV.OnsiteAuditStatus.Active,
                        enerxProjectId: selectedOpp?.id ?? null,
                        opportunityId: selectedOpp?.code ?? null,
                    });
                    if (!original) {
                        history.push(`/audit/${newId}/areas`);    
                    }
                    toggle();
                }}>
                <div className="row">
                {
                    sfdc && !original && (
                        <div className="form-group col-lg-12 mb-1">
                            <Label for='sfdcOpportunity'>Load from Salesforce Opportunity:</Label>
                            <Typeahead 
                                id='sfdcOpportunity'
                                labelKey={renderSfdcOpportunity}
                                options={sfdcOpps}
                                inputProps={{ required: true }}
                                caseSensitive={false}
                                selected={selectedOpp ? [selectedOpp] : []}
                                onChange={([selected]) => setSelectedOpp(selected as EV.IOrgEEOpportunity)}
                                renderMenu={(results, menuProps) => (
                                    <Menu {...menuProps}>
                                    {
                                        results.map((result: EV.IOrgEEOpportunity, index) => (
                                            <MenuItem option={result} position={index} key={result.id}>
                                            { renderSfdcOpportunity(result) }
                                            </MenuItem>
                                    ))}
                                    </Menu>
                                )}
                            />
                        </div>
                    )
                }

                <div className="form-group col-lg-6 col-md-12 mb-1">
                    <Label for='auditor'>Auditor:</Label>
                    <Input 
                        id='auditor' 
                        value={auditorFsid} 
                        type="select"
                        onChange={e => setAuditorFsid(e.target.value)} 
                        required 
                        autoFocus
                    >
                        <option></option>
                        {
                            userList.map(u => <option key={u.fsid} value={u.fsid}>{u.first} {u.last}</option>)
                        }
                    </Input>
                </div>
                <div className="form-group col-lg-6 col-md-12 mb-1">
                    <Label for='date'>Audit Date:</Label>
                    <Input 
                        id='date' 
                        type="date"
                        value={auditDate} 
                        onChange={e => setAuditDate(e.target.value)} 
                        required 
                    />
                </div>
                <div className="form-group col-lg-6 col-md-12 mb-1">
                    <Label for='projectName'>Project Name:</Label>
                    <Input 
                        id='projectName' 
                        disabled={sfdc}
                        value={projectName} 
                        onChange={e => setProjectName(e.target.value)} 
                        required 
                    />
                </div>
                <div className="form-group col-lg-6 col-md-12 mb-1">
                    <Label for='customer'>Customer:</Label>
                    <Input 
                        id='customer' 
                        disabled={sfdc}
                        value={customer} 
                        onChange={e => setCustomer(e.target.value)} 
                        required 
                    />
                </div>
                <div className="form-group col-lg-6 col-md-12 mb-1">
                    <Label for='line1'>Street 1:</Label>
                    <Input 
                        id='line1' 
                        disabled={sfdc}
                        value={street} 
                        onChange={e => setStreet(e.target.value)} 
                        required 
                    />
                </div>
                <div className="form-group col-lg-6 col-md-12 mb-1">
                    <Label for='line2'>Street 2:</Label>
                    <Input 
                        id='line2' 
                        disabled={sfdc}
                        value={street2} 
                        onChange={e => setStreet2(e.target.value)} 
                    />
                </div>
                <div className="form-group col-lg-6 col-md-12 mb-1">
                    <Label for='city'>City:</Label>
                    <Input 
                        id='city' 
                        disabled={sfdc}
                        value={city} 
                        onChange={e => setCity(e.target.value)} 
                        required 
                    />
                </div>
                <div className="form-group col-lg-6 col-md-12 mb-1">
                    <Label for='state'>State:</Label>
                    <Input 
                        id='state' 
                        type="select"
                        disabled={sfdc}
                        value={state} 
                        onChange={e => setState(e.target.value)} 
                        required 
                    >
                        <option></option>
                    {
                        Object.keys(EV.utils.stateData.usStates).map(s => <option key={s}>{s}</option>)
                    }
                    </Input>
                </div>
                <div className="form-group col-lg-6 col-md-12 mb-1">
                    <Label for='zipcode'>ZIP Code:</Label>
                    <Input 
                        id='zipcode' 
                        disabled={sfdc}
                        value={zip} 
                        onChange={e => setZip(e.target.value)} 
                        required 
                    />
                </div>
                <div className="form-group col-lg-6 col-md-12 mb-1">
                    <Label for='facilityType'>Facility Type:</Label>
                    <Input 
                        id='facilityType' 
                        type="select"
                        value={facilityType} 
                        onChange={e => setFacilityType(e.target.value)} 
                        required 
                    >
                        <option></option>
                    {
                        master.facilityType.map(({ id, label }) => <option key={id} value={id}>{label}</option>)
                    }
                    </Input>
                </div>
                <div className="form-group col-lg-12 mb-1">
                    <Label for='facilityNotes'>Facility Notes:</Label>
                    <Input 
                        id='facilityNotes' 
                        value={facilityNotes} 
                        onChange={e => setFacilityNotes(e.target.value)} 
                        type="textarea" 
                    />
                </div>

                <div className="form-group col-lg-6 col-md-12 mb-1">
                    <Label for='name'>Audit Contact:</Label>
                    <Input 
                        id='name' 
                        disabled={sfdc}
                        value={name} 
                        onChange={e => setName(e.target.value)} 
                        required
                    />
                </div>

                <div className="form-group col-lg-6 col-md-12 mb-1">
                    <Label for='phone'>Phone:</Label>
                    <ReactInputMask mask="(999) 999-9999" value={ phone } onChange={ e => setPhone(e.target.value) } disabled={sfdc}>
                    {
                        (inputProps: any) => <Input id="phone" { ...inputProps } 
                                                type='tel'
                                                disabled={sfdc}
                                                placeholder='(123) 456-7890' 
                                                required 
                                                pattern="\(\d{3}\)\s\d{3}-\d{4}"
                                              /> 
                    }
                    </ReactInputMask>
                </div>

                <div className="form-group col-lg-6 col-md-12 mb-1">
                    <Label for='email'>Email:</Label>
                    <Input 
                        id='email' 
                        disabled={sfdc}
                        value={email} 
                        onChange={e => setEmail(e.target.value)} 
                        required
                        type="email" 
                    />
                </div>

                <div className="form-group col-lg-6 col-md-12 mb-1">
                    <Label for='utility'>Utility:</Label>
                
                    <Typeahead 
                        id='utility'
                        filterBy={(option: EV.IUtilityBase, state) => option.name.toLowerCase().includes(state.text.toLowerCase()) || option.code?.toLowerCase().includes(state.text.toLowerCase())}
                        labelKey={(u: EV.IUtilityBase) => u?.name ?? ''}
                        options={utilities.all(true, true)}
                        caseSensitive={false}
                        selected={utilityId ? [utilities.get(utilityId)] : []}
                        onChange={([selected]) => setUtilityId(selected ? (selected as EV.IUtilityBase).id : null)}
                        renderMenu={(results, menuProps) => (
                            <Menu {...menuProps}>
                            {
                                results.map((result: EV.IUtilityBase, index) => (
                                    <MenuItem option={result} position={index} key={result.id}>
                                    { result.name } { result.code ? <b>[{ result.code }]</b> : '' }
                                    </MenuItem>
                              ))}
                            </Menu>
                          )}
                       />
                </div>
                </div>

                <div className="form-group mt-2 text-end">
                    <Button color="secondary" className="me-1" onClick={toggle}>Cancel</Button>
                    <Button color="primary" type="submit">{ original ? 'Update' : 'Add' } Audit</Button>
                </div>
                </Form>
            </ModalBody>
        </Modal>
    )
}

