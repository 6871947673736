import { EV } from 'enerx-shared'
import { Badge, Card, CardBody, Col } from 'reactstrap'
import { useRequiredValidation } from './hooks/lineItem.hooks';
import { useLineProperties } from './hooks/useLineProperties';
import { AlertCircle } from 'react-feather';
import { CSSProperties } from 'react';
import { model } from 'model';
import { IAugmentedLineItem } from 'model/line.item';

const idStyle: CSSProperties = {
    position: 'absolute',
    top: '-4px',
    left: '0px'
};

const alertStyle: CSSProperties = {
    position: 'absolute',
    top: '-5px',
    right: '0px'
}

const itemStyle = {
    cursor: 'pointer',
    margin: '0!important',
}

export const LineItem = ({itm, onClick}: {itm: IAugmentedLineItem, onClick: ()=>void}) => {
    const {properties} = useLineProperties(itm, () => {});
    const { auth } = model.useAppCtx();

    const requiredValidation = useRequiredValidation(properties, auth.features);
    const isValid = requiredValidation.validate();

    return <Col key={itm.fsid} xs={6} sm={4}>
        <Card className='mb-2'>
            <CardBody className='p-1' style={itemStyle} onClick={onClick}>
                <h6 className='me-0 mb-0'>
                {
                    !isValid && <AlertCircle className='text-warning' style={alertStyle}/>
                }
                    {itm.name}-{itm.ftypName} {itm.existingName}
                </h6>
                <Badge color={"light-primary"} style={idStyle}> {EV.utils.fmt.id(itm.id ?? '', 3)}</Badge>
            </CardBody>
        </Card>
    </Col>
}