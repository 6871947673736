// ** React Imports
// ** Custom Components
import Avatar from '@components/avatar'

// ** Third Party Components
import { User, Repeat, Power, Link, Database } from 'react-feather'

// ** Reactstrap Imports
import { UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap'

// ** Default Avatar Image
import defaultAvatar from '@src/assets/images/portrait/small/wattlogic-1-10.png'
import { model } from 'model'
import { refreshCacheAndReload } from 'serviceWorkerRegistration'
import { confirmations } from 'utility/confirmations'
import { Capacitor } from '@capacitor/core'
import { FirebaseAuthentication } from '@capacitor-firebase/authentication'

const UserDropdown = () => {
    const { auth, env, checkStorage } = model.useAppCtx();
    const { user, logout, requestPasswordReset } = auth;

    const confirmAndUpdate = async () => {
        const res = await confirmations.confirmOrCancel({
            title: 'Update application?',
            text: 'Using this action will update the application from the server. Before proceeding, please verify that your device has a reliable and strong internet connection.'
        });
        if (!res.isConfirmed) return;
        if (!navigator.onLine) {
            await confirmations.errorNotification({
                title: 'Your device is not connected to the internet',
                text: `An update can't be made when offline. Please try again when you are connected to the internet.`
            });
            return;
        }
        refreshCacheAndReload();
    }

    const confirmAndLogout = async () => {
        const res = await confirmations.confirmOrCancel({
            title: 'Logging out of the app will require an internet connection to log back in again.',
            text: ''
        });
        if (res.isConfirmed) {
            logout();
        }
    }

    const linkMicrosoft = async () => {
        const res = await confirmations.confirmOrCancel({
            text: 'This action would link your current account with your Microsoft account which would enable you to login using Sign in With Microsoft. Please make sure to link a Microsoft account with the same email address',
            title: 'Link to Microsoft'
        });
        
        if (res.isConfirmed) {
            try {
                if (Capacitor.isNativePlatform()) {
                    await FirebaseAuthentication.linkWithMicrosoft();
                } else {
                    await auth.env.fb.linkWithPopup(auth.env.fb.auth.currentUser, auth.env.fb.providers['microsoft.com'])
                }
                await confirmations.thankYou({
                    text: 'Account successfully linked. You can now Sign in using Microsoft',
                });
            } catch (e) {
                await confirmations.errorNotification({
                    text: 'Seems like the account you have chosen is already linked or another issue is present. Please try again',
                });
            }
        }
    }

    return (
        <>
            <UncontrolledDropdown tag='li' className='dropdown-user nav-item'>
                <DropdownToggle href='/' tag='a' className='nav-link dropdown-user-link' onClick={e => e.preventDefault()}>
                    <div className='user-nav d-sm-flex d-none'>
                        <span className='user-name fw-bold'>{`${user?.first} ${user?.last}`}</span>
                    </div>
                    <Avatar img={defaultAvatar} imgHeight='40' imgWidth='40' status='online' />
                </DropdownToggle>
                <DropdownMenu end>
                    <DropdownItem onClick={confirmAndLogout}>
                        <Power size={14} className='me-75' />
                        <span className='align-middle'>Logout</span>
                    </DropdownItem>
                    <DropdownItem onClick={requestPasswordReset}>
                        <User size={14} className='me-75' />
                        <span className='align-middle'>Change Password</span>
                    </DropdownItem>
                    <DropdownItem onClick={linkMicrosoft}>
                        <Link size={14} className='me-75' />
                        <span className='align-middle'>Link Microsoft Account</span>
                    </DropdownItem>
                    <DropdownItem onClick={checkStorage}>
                        <Database size={14} className='me-75' />
                        <span className='align-middle'>Optimize Storage</span>
                    </DropdownItem>
                {
                    env.platform === 'web' && (
                        <DropdownItem onClick={confirmAndUpdate}>
                            <Repeat size={14} className='me-75' />
                            <span className='align-middle'>Update</span>
                        </DropdownItem>
                    )
                }
                </DropdownMenu>
            </UncontrolledDropdown>
        </>
    )
}

export default UserDropdown
