import Dexie, { Table } from 'dexie';
import { ILocalPhoto } from './types';
import * as CryptoJS from 'crypto-js';

class PhotosDb extends Dexie {
    static readonly DbName = 'local-photos';

    private photos: Table<ILocalPhoto>;

    constructor() {
        super(PhotosDb.DbName);
        this.version(1).stores({
            photos: 'uid, auditFsid'
        });
    }

    async put(photo: ILocalPhoto) {
        await this.photos.add(photo, photo.uid);
    }

    async postUpload(uid: string) {
        await this.update({uid, uploaded: true})
    }
    
    async update(photo: Partial<ILocalPhoto>) {
        if (photo.uid) {
            await this.photos.update(photo.uid, photo)
        }
        else {
            console.error('update photo, no uid', photo);
        }
    }

    async get(uid: string) {
        try {
            const photo = await this.photos.get(uid);
            return photo;
        }
        catch (err) {
            // not found
            return null;
        }
    }

    async getMap(uids: string[]) {
        const list = await this.photos.bulkGet(uids);
        return new Map<string, ILocalPhoto>(list.filter(p => p).map(p => [p.uid, p]));
    }

    async remove(uid: string) {
        await this.photos.delete(uid);
    }

    async removeInAudit(auditFsid: string) {
        await this.photos.filter(p => p.auditFsid == auditFsid).delete();
    }

    async chunk() {
        return this.photos.filter(p => !p.uploaded).limit(10).toArray();
    }

    async getPhotosByAudit(auditFsid: string) {
        return this.photos.filter(p => p.auditFsid == auditFsid).toArray();
    }

    async countsByAudit() {
        const res = new Map<string, number>();
        await this.photos.each(p => {
            const cnt = res.get(p.auditFsid);
            res.set(p.auditFsid, cnt ? cnt + 1 : 1);        
        });
        return res;
    }
}

export const db = new PhotosDb();
