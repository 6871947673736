import { useRef, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { formInput, UseInputNumber, UseInputPicker } from './lineItem.hooks';

export const useUpdateReductionFromControl = (properties: formInput[], _previousControl: string) => {
    const control = properties.find(p => p.field == 'existingControl') as UseInputPicker;
    const reduction = properties.find(p => p.field == 'reduction') as UseInputNumber;
    const previousControl = useRef(_previousControl);

    const isReductionTheSameAsInControl = () => control.collection.find(c => c.id == control.value)?.reduction == reduction.value;
    const [isManualReduction, setIsManualReduction] = useState(!isReductionTheSameAsInControl());

    useEffect(() => {
        if (control.value && control.value != previousControl.current) {
            reduction.setValue(control.collection.find(c => c.id == control.value).reduction);
            if (isManualReduction) {
                toast.success('Reduction was just updated to value from control database.');
                setIsManualReduction(false);
            }
            previousControl.current = control.value;
        }
    }, [control.value]);


    useEffect(() => {
        setIsManualReduction(!isReductionTheSameAsInControl());
    }, [reduction.value]);
}