import { useState } from "react";
import { EV } from 'enerx-shared';

export function useDefaults(original?: EV.IAuditAreaDefaults, required: Partial<Record<keyof EV.IAuditAreaDefaults, boolean>> = {}) {
    const [burnHours, setBurnHours] = useState(original?.burnHours == null ? '' : `${original?.burnHours}`);
    const [fixtureHeight, setFixtureHeight] = useState(original?.fixtureHeight == null ? '' : `${original?.fixtureHeight}`);
    const [ceilingHeight, setCeilingHeight] = useState(original?.ceilingHeight == null ? '' : `${original?.ceilingHeight}`);
    const [voltage, setVoltage] = useState(original?.voltage ?? '');
    const [ac, setAc] = useState(original?.ac ?? '');
    const [heated, setHeated] = useState(original?.heated ?? '');

    const asPatch = () => {
        const patch: EV.IAuditAreaDefaults = {
            burnHours: null,
            fixtureHeight: null,
            ceilingHeight: null,
            voltage: null,
            ac: null,
            heated: null,
        };
        if (burnHours) {
            patch.burnHours = +burnHours;
        }
        if (fixtureHeight) {
            patch.fixtureHeight = +fixtureHeight;
        }
        if (ceilingHeight) {
            patch.ceilingHeight = +ceilingHeight;
        }
        if (voltage) {
            patch.voltage = voltage;
        }
        if (ac) {
            patch.ac = ac;
        }
        if (heated) {
            patch.heated = heated;
        }
        return patch;
    }

    const reset = () => {
        setBurnHours(original?.burnHours == null ? '' : `${original?.burnHours}`);
        setFixtureHeight(original?.fixtureHeight == null ? '' : `${original?.fixtureHeight}`);
        setCeilingHeight(original?.ceilingHeight == null ? '' : `${original?.ceilingHeight}`);
        setVoltage(original?.voltage ?? '');
        setAc(original?.ac ?? '');    
        setHeated(original?.heated ?? '');
    }

    return { burnHours, setBurnHours, fixtureHeight, setFixtureHeight, ceilingHeight, setCeilingHeight, voltage, setVoltage, ac, setAc, asPatch, reset, heated, setHeated, required };
}

export type IDefaultsModel = ReturnType<typeof useDefaults>;
