import Router from './views/Router';
import store from './gstate/store';
import { Provider } from 'react-redux';
import { ThemeContext } from 'utility/context/ThemeColors';
import { ToastContainer } from 'react-toastify'
import { Suspense } from 'react';
import { Spinner } from 'reactstrap';
import '@styles/react/libs/toastify/toastify.scss'
import { model } from './model';

const App: React.FC = () => {
    const appCtx = model.useApp();
    const app = <model.AppCtx.Provider value={appCtx}>
                    <Provider store={store}>
                        <Suspense fallback={<Spinner />}>
                            <ThemeContext >
                                <Router />
                                <ToastContainer style={appCtx.env?.cfg?.platform == 'ios' ? {paddingLeft:'30px', paddingRight:'30px', marginBottom:'20px'} : {}} newestOnTop position={appCtx.env.platform == 'ios' ? 'bottom-center' : 'top-right'} />
                            </ThemeContext>
                        </Suspense>
                    </Provider>
                </model.AppCtx.Provider>
    return appCtx.env?.cfg?.platform == 'ios' ? <div style={{marginLeft:'30px', marginRight:'30px', marginBottom:'0px', marginTop:'50px', height:'95%'}}>
        {app}
    </div> : app;
}
export default App;
