import { EV } from 'enerx-shared';
import { model } from "model";
import { Alert, Button, Form, Modal, ModalBody, ModalHeader } from "reactstrap";
import { DefaultsEditor } from "./DefaultsEditor";

export const AuditDefaultsForm = ({ original, toggle }: { original: EV.IOnsiteAudit, toggle: () => void}) => {
    const { saveAudit } = model.useAuditCtx();
    const defaultsModel = model.useDefaults(original);
    
    return <Modal style={{paddingTop:'50px'}} isOpen={true} toggle={toggle} size='sm'>
    <ModalHeader tag='h4'  toggle={toggle}>Edit Facility Defaults</ModalHeader>
    <ModalBody>
        <div className="row">
            <div className='col-12'>
                <Alert color='primary'>
                    <div className='alert-body'>
                        Values entered below will auto-fill new Areas and Line Items for this facility. Leave fields blank to avoid setting Facility defaults.
                    </div>
                </Alert>
            </div>
        </div>
        <Form onSubmit={async (e) => {
            e.preventDefault();
            await saveAudit({ ...original, ...defaultsModel.asPatch() });
            toggle();
        }}>
            <div className="row">
                <DefaultsEditor model={defaultsModel} />
            </div>
        
        <div className="form-group mt-2 text-end">
            <Button color="secondary" className="me-1" onClick={toggle}>Cancel</Button>
            <Button color="primary" type="submit">{ original ? 'Update' : 'Add' } Audit</Button>
        </div>
        </Form>
    </ModalBody>
</Modal>
}