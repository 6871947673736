import { useEffect, useRef, useState } from "react";
import { IAuthModel } from "./auth";
import { Fstore } from "fb-web";

export function useFstore(auth: IAuthModel) {
    const [ready, setReady] = useState(false);
    const ref = useRef<Fstore>();
    useEffect(() => {
        if (auth.status == 'valid' && !ready) {
            console.log('Initializing fstore', auth.org, auth.fsOrgPrefix)
            ref.current = new Fstore(auth.env.fb.firestore, auth.org, auth.fsOrgPrefix);
            ref.current.waitOrgs().then(() => setReady(true)).catch(console.error);
        }
    }, [auth]);
    
    return { ready, fstore: ref.current };
}