// Get all line items for audit and, optionally area, and CRUD operations on line item records

import { EV } from 'enerx-shared';
import { useEffect, useMemo, useState } from "react";
import { UpdateUtils } from "./utils";
import { confirmations } from 'utility/confirmations';
import { toast } from 'react-toastify';
import { useAppCtx } from "./ctx";
import { PhotoManager } from "./photos";

export type IAugmentedLineItem = EV.IOnsiteAuditLineItem & { ftypName: string, existingName: string };

// (i.e. if areaFsid is not provided we return all items inside the audit, if provided - only inside the area)
export function useLineItem(master: EV.IMasterData, activeAuditExistings: EV.IExistingFixture[], auditFsid: string, areaFsid?: string) {
// export function useLineItem(auditFsid: string, areaFsid?: string) {
//     const { fstore, photos, api, log } = useAppCtx();
    const { fstore, api, log } = useAppCtx();
    const [maxId, setMaxId] = useState(0);
    const [list, setList] = useState<IAugmentedLineItem[]>();

    const lineItemCol = useMemo(() => fstore.auditLineItem(auditFsid), [auditFsid]);

    useEffect(() => {
        setList([]); // Clean the list immediately as load takes time
        return lineItemCol.subscribeByArea(areaFsid, newLst => {
            const augmented = newLst.map(itm => {
                const ftyp = master.existingFType.find(e => e.id == itm.existingFType);
                const existing = activeAuditExistings.find(e => e.fsid == itm.existingFixtureFsid);
                return { ...itm, ftypName: ftyp?.label ?? '', existingName: existing?.id ?? '' };
            });
            setList(augmented);
        });
    }, [auditFsid, areaFsid]);
    useEffect(() => lineItemCol.subscribeMaxId(setMaxId), [auditFsid]);

    const utils = new UpdateUtils(log);
    const save = async (a: Partial<EV.IOnsiteAuditLineItem>) => {
        if (!a.fsid) {
            a = { ...a, id: (maxId ?? 0) + 1 }
        }
        return utils.updateAndToast(a, lineItemCol, true);
    }

    const duplicate = async (a: Partial<EV.IOnsiteAuditLineItem>) => {
        const { fsid, name, photos, notes, qty, createdAt, updatedAt, ...rest } = a;
        return utils.updateAndToast({ ...rest, name: `[COPY] ${name}`, id: (maxId ?? 0) + 1 }, lineItemCol);
    }

    const removeAllPhotos = async (item: EV.IOnsiteAuditLineItem) => new PhotoManager(api, lineItemCol.photoCol(item.fsid)).removeAll();

    const remove = async (item: EV.IOnsiteAuditLineItem) => {
        const conf = await confirmations.confirmOrCancel({ title: `Are you sure you want to delete this ${lineItemCol.title}?`, text: '' });
        if (!conf.isConfirmed) return;

        try {
            await removeAllPhotos(item);
            await lineItemCol.remove(item.fsid);

            toast.success(`Successfully removed ${lineItemCol.title}`);
        }
        catch (err) {
            console.error(err);
            const msg = (err instanceof EV.BusinessError) ? err.message : `Unexpected error removing ${lineItemCol.title}. Please try again in a moment.`;
            toast.error(msg);
        }
    }


    return { list, maxId, save, remove, removeAllPhotos, duplicate, areaFsid, auditFsid }
}

export type ILineItemModel = ReturnType<typeof useLineItem>;
