import { ILocalPhoto } from "./photos/types";
import { IAuthModel } from "./auth";
import { EV } from 'enerx-shared';
import { Log } from "./log";
import { ILocalEnv } from "./local.env";
import axios from "axios";

export class Api {
    private token: string;
    constructor(private auth: IAuthModel, private log: Log, private env: ILocalEnv) {
        this.token = auth.token;
    }

    get org() {
        return this.auth.org;
    }
    
    async uploadPhoto(photo: ILocalPhoto) {
        const form = new FormData();
        form.append('file', photo.file);
        await axios.post(`${this.env.cfg.eeapi}/onsite-audits/${photo.auditFsid}/photos/${photo.uid}`, form, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${this.token}`
            },
          });
    }

    async removePhoto(auditFsid: string, uid: string) {
        await this.delete(`${auditFsid}/photos/${uid}`);
    }

    async report(auditFsid: string, email: string) {
        await this.post(`${auditFsid}/report`, { email });
    }

    async photos(auditFsid: string, email: string) {
        await this.post(`${auditFsid}/photos`, { email });
    }

    async completeAudit(auditFsid: string) {
        await this.post(`${auditFsid}/complete`, {});
    }

    private get(endpoint: string) {
        return this.call(endpoint, 'GET');
    }

    private post(endpoint: string, body: object) {
        return this.call(endpoint, 'POST', JSON.stringify(body));
    }

    private delete(endpoint: string) {
        return this.call(endpoint, 'DELETE');
    }

    async checkPhotoServerStatuses(auditFsid: string, fileList: { uid: string, hash: string }[]) {
        return await this.post(`${auditFsid}/photos/check`, { fileList }) as { statuses: Record<string, boolean> };
    }

    private makeHeaders(json: boolean) {
        const h: Record<string, string> = {
            Authorization: `Bearer ${this.token}`,
        }
        if (json) {
            h['content-type'] = 'application/json';
        }
        return h;
    }

    private async call<T>(endpoint: string, method: 'GET' | 'POST' | 'DELETE' | 'PATCH' | 'UPDATE' = 'GET', body?: BodyInit, json = true) {
        if (!navigator.onLine) {
            throw new Error('This feature is not available offline');
        }
        const url = `${this.env.cfg.eeapi}/onsite-audits/${endpoint}`;


        const params: RequestInit = {
            method,
            headers: this.makeHeaders(json),
            body,
        };

        try {
            let res = await this.env.fetch(url, params);
    
            if (res.status == 401) {
                // invalid token ... refresh
                this.token = await this.auth.refreshToken();
                if (!this.token) {
                    this.log.error('Token invalid and cannot refresh', { url, params });
                    throw new Error('Not authorized');
                }
                
                params.headers = this.makeHeaders(json);
                res = await this.env.fetch(url, params);
            }
    
            if (res.ok) {
                const data: T = await res.json();
                return data;
            }
            else {
                const { method, body, headers } = params;
                const result = await res.json();
                this.log.error(`Server returned error when calling API method ${endpoint}`, { url, method, body, headers, result });
                throw new EV.BusinessError(result?.message);
            }    
        }
        catch (err) {
            if (!(err instanceof EV.BusinessError)) {
                // Most likely no network
                const { method, body, headers } = params;
                this.log.error(`Error calling API method ${endpoint}`, { err, url, method, body, headers });
            }
            throw err;
        }
    }

}