import { useRef, useState } from 'react'
import { Badge, Col, Input, Label, Row } from 'reactstrap';
import { Typeahead } from "react-bootstrap-typeahead";
import { EV, EE } from 'enerx-shared';
import { confirmations } from 'utility/confirmations';
import { model } from 'model';
import Select from 'react-select'

export const selectThemeColors = theme => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: '#30a2b8a', // for option hover bg-color
      primary: '#30a2b8', // for selected option bg-color
      neutral10: '#30a2b8', // for tags bg-color
      neutral20: '#ededed', // for input border-color
      neutral30: '#ededed' // for input hover border-color
    }
  })
  
const InputWrapper = ({name: field, title, input} : {name: string, title: string, input: JSX.Element}) => {
    return <div className="form-group mb-1">
        <Label for={field}>{title}:</Label>
        {input}
    </div>;
}

const useIsRequireMet = (value: any, required?: boolean) => {
    return !required || !!value || value === 0;
}

const useIsPropModified = (value: string|boolean) => {
    const originalValue = useRef(value);
    const isModified = () => {
        return originalValue.current != value;
    }
    return {isModified};
}

export const useInputText = function <T>(field: keyof T & string, title: string, item: T, setter: any, required?: boolean) {
    // const [value, setValue] = useState<string>(item?.[field] as string ?? '');
    const value = item?.[field] as string ?? '';
    const setValue = (v: string) => { setter((l: EV.IOnsiteAuditLineItem) => ({...l, [field]: v})) } 
    const modified = useIsPropModified(value);
    const isRequireMet = useIsRequireMet(value, required);

    const Component = <InputWrapper key={field} name={field} title={title} input={
            <Input value={value} onChange={(e) => setValue(e.target.value)} type='text' required={!!required}/>
        }
    />

    return {value, field, title, Component, required, isRequireMet, isModified: modified.isModified()};
}

export const useInputMultilineText = function <T>(field: keyof T & string, title: string, item: T, setter: any, required?: boolean) {
    const value = item?.[field] as string ?? '';
    const setValue = (v: string) => { setter((l: EV.IOnsiteAuditLineItem) => ({...l, [field]: v})) }
    const modified = useIsPropModified(value);
    const isRequireMet = useIsRequireMet(value, required);

    const Component = <InputWrapper key={field} name={field} title={title} input={
            <Input autoFocus style={{height: '400px'}} value={value} onChange={(e) => setValue(e.target.value)} type='textarea' required={!!required}/>
        }
    />

    return {value, field, title, Component, required, isRequireMet, isModified: modified.isModified()};
}

type UseInputMultilineText = ReturnType<typeof useInputMultilineText>;

type UseInputText = ReturnType<typeof useInputText>;

export const useInputNumber = function <T>(field: keyof T & string, title: string, item: T, setter: any, type?: 'int'|'float', required?: boolean) {
    const value = item?.[field] == null ? '' : item[field].toString();
    const setValue = (v: number) => { setter((l: EV.IOnsiteAuditLineItem) => ({...l, [field]: isNaN(v) ? null : v})) }
    const modified = useIsPropModified(value);
    const isRequireMet = useIsRequireMet(value, required);

    const options = {min: 0};
    const Component = <InputWrapper key={field} name={field} title={title} input={
            <Input id={field} value={value} type='number' required={!!required} {...options}
                onChange={(e) => {
                    setValue(type != 'int' ? parseFloat(e.target.value) : parseInt(e.target.value));
                }}
            />
        }
    />

    return {value, setValue, field, title, required, isRequireMet, Component, isModified: modified.isModified()};
}
export type UseInputNumber = ReturnType<typeof useInputNumber>;

export const useInputCheckbox = function <T>(field: string, title: string, item: T, setter: any, required?: boolean) {
    const value = item?.[field] as boolean ?? false;
    const setValue = (v: boolean) => { setter((l: EV.IOnsiteAuditLineItem) => ({...l, [field]: v})) }

    const modified = useIsPropModified(value);
    const isRequireMet = useIsRequireMet(value, required);

    const Component = <InputWrapper key={field} name={field} title={title} input={
            <><br/><Input id={field} checked={value} onChange={(e) => setValue(e.target.checked)} type='checkbox' required={!!required}/></>
        }
    />

    return {value, field, title, Component, required, isRequireMet, isModified: modified.isModified()};
}
type UseInputCheckbox = ReturnType<typeof useInputCheckbox>;

export const useInputPicker = function <T>(field: keyof T & string, title: string, item: T, collection: any[], setter: any, required?: boolean, mapper?: (e: any) => {id: string, label: string}, isMaster = true, extra?: {setValue?: (v: string) => void}) {
    const { auth } = model.useAppCtx();
    const value = item?.[field] as string ?? '';
    const setValue = extra?.setValue ?? ((v: string) => { setter((l: EV.IOnsiteAuditLineItem) => ({...l, [field]: v})) });

    const modified = useIsPropModified(value);
    const isRequireMet = useIsRequireMet(value, required);

    let collectionActual = isMaster ? collection?.filter(e => e.status === EV.MasterStatus.Active || e.id == value) : collection;
    collectionActual = collectionActual?.map(e => (mapper ? mapper(e) : e)) ?? [];

    const sortFunc = (a: {label: string}, b: {label: string}) => {
        const aNumber = parseFloat(a.label);
        const bNumber = parseFloat(b.label);
        if (!isNaN(aNumber) && !isNaN(bNumber)) return aNumber - bNumber;

        return (a.label > b.label ? 1 : -1);
    }

    collectionActual = auth.features.support.orderMasterEntitiesAlphabetically ? collectionActual.sort(sortFunc) : collectionActual;

    const Component = <InputWrapper key={field} name={field} title={title} input={
        <Input id={field} value={value} onChange={(e) => setValue(e.target.value) } type='select' required={!!required}> 
            <option></option>
            {collectionActual.map(e => <option disabled={isMaster && e.status != EV.MasterStatus.Active} key={e.id} value={e.id}>{e.label}</option>)}
        </Input>}
    />

    return {value, field, title, collection, required, isRequireMet, Component, isModified: modified.isModified()};
}
export type UseInputPicker = ReturnType<typeof useInputPicker>;

export const useFixtureTypeInputPicker = function <T>(field: keyof T & string, title: string, item: T, collection: any[], setter: any, required?: boolean, mapper?: (e: any) => {id: string, label: string}, isMaster = true) {
    const setValue = (v: string) => {
        setter((l: EV.IOnsiteAuditLineItem) => {
            const fixtureDetail = (collection.find(c => c.id === v) as EV.IExistingFTypeRecord)?.details?.find(c => c === l.fixtureDetail) ? {} : {fixtureDetail: null};
            return ({...l, [field]: v, ...fixtureDetail });
        })
    }
    
    return useInputPicker(field, title, item, collection, setter, required, mapper, isMaster, {setValue});
}
export type UseFixtureTypeInputPicker = ReturnType<typeof useFixtureTypeInputPicker>;

const ifEmpty = (current: string, defl: string) => {
    if (!current || current.trim().length == 0) return defl;
    return current;
}

export const useAreaPicker = function <T>(item: T, areas: EV.IArea[], setter: any) {
    const setValue = (v: string) => {
        setter((l: EV.IOnsiteAuditLineItem) => {
            const area = areas.find(a => a.fsid == v);

            const burnHours = area?.burnHours ?? l.burnHours;
            const fixtureHeight = area?.fixtureHeight ?? l.fixtureHeight;
            const ceilingHeight = area?.ceilingHeight ?? l.ceilingHeight;
            const voltage = ifEmpty(area?.voltage, l.voltage);
            const ac = ifEmpty(area?.ac, l.ac);
            return {...l, areaFsid: v, burnHours, fixtureHeight, ceilingHeight, voltage, ac };
        })
    }
    
    return useInputPicker('areaFsid' as any, 'Area', item, areas, setter, true, (e) => ({...e, id: e.fsid, label: e.name}), false, {setValue});
}


export const useInputTypeAhead = function <T>(field: keyof T & string, title: string, item: T, collection: any[], setter: any, required?: boolean) {
    const value = item?.[field] as string ?? '';
    const setValue = (v: string) => { setter((l: EV.IOnsiteAuditLineItem) => ({...l, [field]: v})) }

    const modified = useIsPropModified(value);
    const isRequireMet = useIsRequireMet(value, required);

    const Component = <InputWrapper key={field} name={field} title={title} input={
        <Typeahead id={field} onChange={([selected]) => setValue(selected ? (selected as any).fsid : '') }
            options={collection}
            inputProps = {{ required }}
            defaultSelected={[collection.find(x => x.fsid == value)]}
            labelKey={'id'}
        />
    }
    />

    return {value, field, title, collection, required, isRequireMet, Component, isModified: modified.isModified()};
}
type UseInputTypeAhead = ReturnType<typeof useInputTypeAhead>;

export const useInputMultiSelect = function <T>(field: keyof T & string, title: string, item: T, collection: any[], setter: any, required?: boolean) {
    const value = item?.[field] as string[] ?? [];
    const setValue = (v: string[]) => { setter((l: EV.IOnsiteAuditLineItem) => ({...l, [field]: v})) }

    const combinedValue = value.sort((a, b) => (a > b ? 1 : -1)).join();
    const modified = useIsPropModified(combinedValue);
    const isRequireMet = useIsRequireMet(value, required);

    const uiCollection = collection?.map(c => ({ value: c.id, label: c.label })) ?? [];
    const defaultValue = uiCollection.filter(p => value.includes(p.value));

    const onChange = (value, { action, removedValue }) => {
        setValue(value.map(m => m.value));
    }
    //     { value: 'purple', label: 'Purple', color: '#5243AA', isFixed: true },
    const Component = <InputWrapper key={field} name={field} title={title} input={
        <Select
            isClearable={false}
            theme={selectThemeColors}
            defaultValue={defaultValue}
            isMulti
            name='colors'
            options={uiCollection}
            className='react-select'
            onChange={onChange}
            classNamePrefix='select'
        />
    }
    />

    return {value, field, title, collection, required, isRequireMet, Component, isModified: modified.isModified()};
}
type UseInputMultiSelect = ReturnType<typeof useInputMultiSelect>;

export const isAnyModified = (properties: any[]) => properties.some(p => p.isModified); // todo better to use formInput[] but issue with order

export const useInputExisting = function (title: string, item: EV.IOnsiteAuditLineItem, collection: EV.IExistingFixture[], setter: any, required?: boolean) {
    const value = item?.existingFixtureFsid as string ?? '';
    const setValue = (v: string) => { setter((l: EV.IOnsiteAuditLineItem) => ({...l, existingFixtureFsid: v})) } // todo use this approch '(l: EV.IOnsiteAuditLineItem) => ({...l,'  in other places
    
    const modified = useIsPropModified(value);
    
    const originalEF = collection.find(c => c.fsid == item?.existingFixtureFsid);
    const typeaheadOpts = [
        {fsid: null, id: ''},
        ...collection.filter(
            e => EV.onsiteAuditUtils.isCustomExisting(e) 
            || e.status == EV.MasterStatus.Active 
            || e.fsid == item?.existingFixtureFsid
        )
    ];
    const isCustom = EV.onsiteAuditUtils.isCustomExisting(originalEF);
    const [custom, setCustom] = useState(isCustom ? originalEF : {fsid: null} as EV.IExistingFixture);
    const [isCustomOpened, setIsCustomOpened] = useState(false);
    const isRequireMet = useIsRequireMet(value, required);

    const properties = [ // move into another internal hook, but global issue here is that we always should call these hooks even if we do not need them,
        useInputText<EV.IExistingFixture>('id', 'ID', custom, setCustom, true),
        useInputNumber<EV.IExistingFixture>('watts', 'System Watts', custom, setCustom, 'int', true),
        useInputNumber<EV.IExistingFixture>('numLamps', 'Num Lamps', custom, setCustom, 'int', true),
        useInputText<EV.IExistingFixture>('description', 'Description', custom, setCustom),
    ]
    const isRequireCustomMet = () => properties.every(p => p.isRequireMet);
    const isGeneralRequireMet = isCustomOpened ? isRequireCustomMet() : isRequireMet;

    const isModified = modified.isModified() || isAnyModified(properties);

    const customButtomLabel = isCustomOpened ? 'Select Existing' : (isCustom ? 'Edit Custom' : 'Create Custom');
    const Component = <div className="form-group mb-1">
        <Row>
            <Col xs={7}><Label for='existingFixtureFsid'>{title}:</Label></Col>
            <Col xs={5} className="text-end"><Badge color='info' onClick={() => setIsCustomOpened(!isCustomOpened)}>{customButtomLabel}</Badge></Col>
        </Row>
        {!isCustomOpened && 
            <Typeahead id={'existingFixtureFsid'} 
                onChange={([selected]) => setValue(selected ? (selected as any).fsid : '')}
                options={typeaheadOpts}
                inputProps = {{ required }}
                labelKey={option => `${EV.onsiteAuditUtils.isCustomExisting(option as EV.IExistingFixture) ? '[custom] ' : ''}${option['id']}`}
                selected={collection.filter(x => x.fsid == value)}
            />}
        {isCustomOpened && 
            <Row>
                {properties.map(p => <Col className="ms-2" key={p.field} xs={11} md={5}>
                        {p.Component}
                    </Col>
                )}                        
            </Row>
        }
    </div>;
    
    return {value, field: 'existingFixtureFsid', title, collection, required, isRequireMet: isGeneralRequireMet, setValue, Component, isModified, isCustomOpened, custom};
} // todo do we need expose 'collection', 'required'
export type UseInputExisting = ReturnType<typeof useInputExisting>;

export type formInput = UseInputPicker|UseFixtureTypeInputPicker|UseInputNumber|UseInputText|UseInputCheckbox|UseInputTypeAhead|UseInputExisting|UseInputMultilineText|UseInputMultiSelect;

const useCostNoteValidation = () => {
    const isAddCostNotesRequired = (properties: formInput[], features: EE.IStaticOrgFeatures) => {
        if (!features.support.auditNotesSmartRequired) return false;
        const addMatCost = properties.find(p => p.field === 'addMatCost');
        const addLaborCost = properties.find(p => p.field === 'addLaborCost');
        return addMatCost?.value || addLaborCost?.value;
    }
    const requiredCostNotesPresent = (properties: formInput[], features: EE.IStaticOrgFeatures) => {
        const addCostNotes = properties.find(p => p.field === 'addCostNotes');
        const addCostNotesRequired = isAddCostNotesRequired(properties, features);
        return !addCostNotesRequired || addCostNotes?.value;
    }
    return {isAddCostNotesRequired, requiredCostNotesPresent};
}

type FilterPresets = 'all'|'required';
export const useLinePropertiesFilter = () => {
    const [filterText, setFilterText] = useState('');
    const [filterSelect, setFilterSelect] = useState<FilterPresets>('all');
    const {isAddCostNotesRequired} = useCostNoteValidation();

    const applyFilters = (properties: formInput[], features: EE.IStaticOrgFeatures) => {
        const isPropertyIndividuallyRequired = (property: keyof EV.IOnsiteAuditLineItem) => {
           if (property === 'addCostNotes') return isAddCostNotesRequired(properties, features);
           return false;
        }

        const filterRequired = (p: formInput) => p.required || isPropertyIndividuallyRequired(p.field);
        const orderedLineFields = features.support.orderPatternLineItemProperties;    

        return properties
            .filter(p => orderedLineFields.includes(p.field))
            .filter(p => (filterSelect === 'required' ? filterRequired(p) : true))
            .filter(p => p.title.toLowerCase().includes(filterText.toLowerCase()));
    }

    const Component = <>
        <Row>
            <Col xs={2} md={7} lg={9}></Col>

            <Col xs={5} md={3} lg={2} className='p-0'>
                <div className='text-end'>
                    <Input id='filterText' autoFocus placeholder='Filter by Fields' value={filterText} onChange={e => setFilterText(e.target.value)}/>
                </div>
            </Col>
            <Col xs={5} md={2} lg={1} className='p-0'>
                <div className='text-end'>
                    <Input id='filterSelect' value={filterSelect} className={'ps-1 pe-0 bg-light-primary'} onChange={e => { setFilterText(''); setFilterSelect(e.target.value as FilterPresets) }} type='select'> 
                        <option key='all' value='all'>All</option>
                        <option key='required' value='required'>Required</option>
                    </Input>
                </div>
            </Col>
        </Row>
    </>
    return {setFilterSelect, setFilterText, applyFilters, Component}

}
export type UseLinePropertiesFilter = ReturnType<typeof useLinePropertiesFilter>;

export const useRequiredValidation = (properties: formInput[], features: EE.IStaticOrgFeatures, filter?: UseLinePropertiesFilter, setMode?: (p: 'props') => void) => {
    const orderedLineFields = features.support.orderPatternLineItemProperties;    
    const isRegularRequiredMet = () => !properties
    .filter(p => orderedLineFields.includes(p.field)) // todo logic is a bit copypasted but to make is better more refactoring needed
    .some(p => !p.isRequireMet);
    const {requiredCostNotesPresent} = useCostNoteValidation();

    const validate = () => {
        return isRegularRequiredMet() && requiredCostNotesPresent(properties, features);
    }

    const validateAndAct = () => {
        const requiredPresent = validate();
        if (!requiredPresent) {
            confirmations.warningNotification({title: `Not all required fields have been completed.`, text: `The 'Required' filter has been applied to show all required fields.`});
            setMode?.('props');
            filter?.setFilterSelect('required');
            filter?.setFilterText('');
            return false;
        }
        return true;
    }

    return {validate, validateAndAct}
}
export type UseRequiredValidation = ReturnType<typeof useRequiredValidation>;
