import { useMemo, useState } from "react"
import { Alert, Button, Card, CardBody, CardText, Form, Input, Label } from "reactstrap"
import '@styles/react/pages/page-authentication.scss';
import { Redirect } from "react-router-dom";
import { GIcon } from "./common/GIcon";
import { MSIcon } from "./common/MSIcon";
import { NativeAuth, model } from "model";
import { AppleIcon } from "./common/AppleIcon";

export const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [sms, setSms] = useState('');

    const { auth, env } = model.useAppCtx();
    const [, updateState] = useState<object>();

    const eeAuth = useMemo(() => new NativeAuth(env, () => {
        if (eeAuth.isAuthenticated) {
            auth.authenticated(eeAuth.auth);
        }
        else {
            updateState({});
        }
    }), []);


    if (auth.status == 'invalid') return <Redirect to="/not-authorized" />;
    if (auth.status == 'valid') return <Redirect to="/home" />;
    
    return (
        
        <div style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'flex-start'}} >
                <div id="recaptcha-container"></div>
                <img  
                    src={'/enerx.png'} 
                    width={`200px`} 
                    alt="Photo may become available in a few minutes"
                    onError={e => { e.currentTarget.src = '/enerx.png' }}
                    style={{marginBottom:'20px'}}
                />
        {
            !eeAuth.isSmsRequired && (
              
                
                <Card className='mb-0'>
                <CardBody>
                    <CardText className='mb-2'>
                        Welcome to SiteLogic, to begin, please login below.
                    </CardText>

                    {
                        eeAuth.errorMessage && <Alert color="warning">
                            <div className="alert-body">
                                {eeAuth.errorMessage}
                            </div>
                        </Alert>
                    }
                    
                    <Form className='auth-login-form mt-2' onSubmit={e => {
                                    e.preventDefault();
                                    eeAuth.passwordSignIn(email, password);
                                }}>
                        <div className='mb-1'>
                            <Label className='form-label' for='login-email'>
                                Email
                            </Label>
                            <Input 
                                type='email' 
                                id='login-email' 
                                placeholder='john@example.com' 
                                autoFocus 
                                required
                                value={email} 
                                onChange={e => setEmail(e.target.value)} 
                            />
                        </div>
                        <div className='mb-1'>
                            <Label className='form-label' for='login-password'>
                                Password
                            </Label>
                            <Input 
                                type='password' 
                                id='login-password' 
                                required
                                value={password} 
                                onChange={e => setPassword(e.target.value)} 
                            />
                        </div>
                        <Button color="primary" block disabled={eeAuth.isLoading}>
                            Sign In
                        </Button>
                    </Form>

                    <div className='auth-footer-btn mt-4'>
                        <button className='btn btn-outline-primary btn-large d-block w-100' onClick={e => eeAuth.providerSignIn('google.com')}>
                            <div className='float-start'>
                                <GIcon />
                            </div>
                            <div className='float-none fw-bolder'>
                                Continue with Google
                            </div>
                        </button>

                        <button className='btn btn-outline-primary btn-large d-block w-100 mt-1' onClick={e => eeAuth.providerSignIn('microsoft.com')}>
                            <div className='float-start'>
                                <MSIcon />
                            </div>
                            <div className='float-none fw-bolder'>
                                Continue with Microsoft
                            </div>
                            </button>

                            {env.platform == 'ios' ? <button className='btn btn-outline-primary btn-large d-block w-100 mt-1' onClick={e => eeAuth.providerSignIn('apple.com')}>
                            <div className='float-start'>
                                <AppleIcon />
                            </div>
                            <div className='float-none fw-bolder'>
                                Continue with Apple
                            </div>
                            </button> : ''}
                    </div>

                </CardBody>
            </Card>  
                        
            )
        }
        {
            eeAuth.isSmsRequired && (
                <Card className='mb-0'>
                <CardBody>
                    <CardText className='mb-2'>
                    Please enter the code we just sent to your phone.
                    </CardText>
                    {
                        eeAuth.isInvalidSms && <Alert color="warning">
                            <div className="alert-body">
                                {eeAuth.errorMessage}
                            </div>
                        </Alert>
                    }

                    <Form className='auth-login-form mt-2' onSubmit={e => {
                                    e.preventDefault();
                                    eeAuth.complete2FA(sms);
                                }}>
                        <Input 
                            type='text' 
                            placeholder='123456' 
                            autoFocus 
                            required
                            value={sms} 
                            onChange={e => setSms(e.target.value)} 
                        />
                                
                        <Button color="primary" block className="mt-1" disabled={eeAuth.isLoading}>
                            Sign In
                        </Button>
                    </Form>
                </CardBody>
                </Card>
            )
        }
        </div>
    )
}