import { useEffect, useMemo, useState } from "react";
import { EV } from 'enerx-shared';
import { UpdateUtils } from "./utils";
import { useAppCtx } from "./ctx";
import { useHistory, useParams } from "react-router-dom";
import { TheSwal, confirmations, defaultConfirmStyle } from "utility/confirmations";
import { toast } from "react-toastify";
import { IAuditPhotoStatusesModel, loadPhotoStatuses } from "./photos/hooks";
import { PhotoCleanupManager } from "./photos";
import { SweetAlertIcon } from 'sweetalert2';

export function useAudit() {
    const { ready, fstore, api, log, auth, env } = useAppCtx();
    const history = useHistory();
    const [master, setMaster] = useState<EV.IMasterData>({
        existingFType: [],
        fixtureDetail: [],
        powerMethod: [],
        mountingMethod: [],
        difficultyFactor: [],
        voltage: [],
        ac: [],
        lampDetail: [], 
        existingControl: [],
        ceilingType: [],
        specialEquipment: [],
        poleSize: [],
        poleType: [],
        poleColor: [],
        lensType: [],
        proposedAction: [],
        facilityType: [],
        areaType: [],
        heated: [],
        existing: [],
    });
    const params = useParams<{ id: string }>();
    const activeAuditId = params.id ?? localStorage.getItem('active-audit-id');
    // "Active" audit - one that was recently started on this device or recently visited
    const [activeAudit, setActiveAudit] = useState<EV.IOnsiteAudit>(); 
    const [activeAuditAreas, setActiveAuditAreas] = useState<EV.IArea[]>(); 
    const [customExistings, setCustomExistings] = useState<EV.IExistingFixture[]>([]);
    const [utilities, setUtilities] = useState<EV.IUtilityBase[]>([]);

    // All audits
    const [list, setList] = useState<EV.IOnsiteAudit[]>([]);

    const [userList, setUserList] = useState<EV.IOnsiteAuditUser[]>([]);
    const [sfdcOpps, setSfdcOpps] = useState<EV.IOrgEEOpportunity[]>([]);

    useEffect(() => {
        if (ready) {
            const unsAudits = fstore.audit.subscribeAllActive(setList);
            const unsMaster = fstore.master.subscribeMasterData(setMaster);
            const unsUsers = fstore.user.subscribeAll(all => all && setUserList(all.filter(u => u.role != 'test')));
            const unsUtils = fstore.subscribeUtilities(setUtilities);
            const unsOpps = fstore.org.subscribeSfdcOpportunities(setSfdcOpps);

            return () => {
                unsAudits();
                unsMaster();
                unsUsers();
                unsUtils();
                unsOpps();
            }
        }
    }, [ready]);


    useEffect(() => {
        if (!ready || !activeAuditId) return;
        return fstore.audit.subscribeOne(activeAuditId, audit => {
            setActiveAudit(audit);
            if (audit) {
                localStorage.setItem('active-audit-id', audit.fsid);    
            }
            else {
                // there is id in params but it resolves to nothing...probably deleted audit
                history.push('/home');
            }
        });
    }, [ready, activeAuditId]);

    const [lineItemCol, areaCol, customCol] = useMemo(() => (
        ready && activeAudit ? [
            fstore.auditLineItem(activeAudit.fsid),
            fstore.auditArea(activeAudit.fsid),
            fstore.customExisting(activeAudit.fsid),
        ] : [
            null,
            null,
            null,
        ]
    ), [ready, fstore, activeAudit])

    useEffect(() => {
        // If activeAudit is resovled - it's valid, i.e. we followed valid url and it still exists - resolve dependencies
        if (activeAudit) {
            const unsAreas = areaCol.subscribeAll(setActiveAuditAreas);
            const unsCustom = customCol.subscribeAll(setCustomExistings);
            return () => {
                unsAreas();
                unsCustom();
            }
        }
    }, [activeAudit]); 

    const activeAuditExistings = useMemo(() => [
        ...master.existing,
        ...customExistings
    ], [master, customExistings]);

    const utils = new UpdateUtils(log);
    const saveAudit = (a: Partial<EV.IOnsiteAudit>) => utils.updateAndToast(a, fstore.audit);
    const removeAudit = (fsId: string) => utils.confirmDeleteAndToast(fsId, fstore.audit);
    const saveArea = (a: Partial<EV.IArea>) => utils.updateAndToast(a, areaCol);
    const removeArea = (fsId: string) => utils.confirmDeleteAndToast(fsId, areaCol);
    const saveExisting = (a: Partial<EV.IExistingFixture>) => customCol.save(a);

    const reorder = async () => {
        const conf = await confirmations.confirmOrCancel({ title: 'Confirm reorder of all line items starting at 1?', text: `A stable internet connection is required to perform this action.` });
        if (!conf.isConfirmed) return;

        try {
            await lineItemCol.reorder();
            toast.success('Reorder completed');
        }
        catch (err) {
            const msg = err.message?.includes('Failed to get documents from server.') ? `This function is not available offline` : `Unexpected error reordering. Please try again in a moment.`
            toast.error(msg);
        }
    }

    const emailAudit = async () => {
        const res = await TheSwal.fire({
            icon: 'info',
            title: 'Please enter an email to send the audit report:',
            text: '',
            input: 'email',
            showCancelButton: true,
            inputValue: auth.user.email,
            padding: '20px',
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-warning ms-1'
            },
            buttonsStyling: false 
        });
        if (res.isConfirmed) {
            const email = res.value;
            try {
                await api.report(activeAudit.fsid, email);
                toast.success('Email Sent Successfully!');
            }
            catch (err) {
                console.error(err)
                toast.error(err.message ?? `Unexpected error emailing audit. Please try again in a moment.`);
            }
        }
    }

    const emailPhotos = async () => {
        const res = await TheSwal.fire({
            icon: 'info',
            title: 'Please enter an email to send audit photos.',
            text: 'Export time may vary based on file size and quantity of photos. The export may take anywhere from a few minutes to an hour.',
            input: 'email',
            showCancelButton: true,
            inputValue: auth.user.email,
            padding: '20px',
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-warning ms-1'
            },
            buttonsStyling: false 
        });
        if (res.isConfirmed) {
            const email = res.value;
            api.photos(activeAudit.fsid, email)
                .then(url => console.log('Photos generated and emailed', url))
                .catch(err => {
                    console.error(err)
                    toast.error(err.message ?? `Unexpected error emailing photos. Please try again in a moment.`);
                });
        }
    }

    const completeAudit = async () => {
        const warningAuditDialog = {
            icon: 'info' as SweetAlertIcon,
            title: 'Action required to complete the audit?', 
            html: `<p>Some photos which were made on another device are not in cloud yet.</p>
            <hr/>
            <div m-1'>
                To transfer all photos correctly, you need to proceed with the upload from the "Manage Photos" screen of the device where the photos were taken.
            </div>
            <hr/>
            <p>Do you still want to complete the audit now?</p>
            `,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        };

        const completeAuditDialog = {
            icon: 'info' as SweetAlertIcon,
            title: 'Confirm completion of audit?', 
            html: `<p>An opportunity will be created in EnerX based on this audit data.
            A stable internet connection is required to perform this action.</p>
            <hr/>
            <div class='form-check form-switch m-1'>
                <input type="checkbox" id="chkConfirmCleanup" checked class='form-check-input'>
                <label for="chkConfirmCleanup" class='text-start form-check-label form-label fs-4'>Cleaup audit photos from this device after completion.</label>
            </div>
            `,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
            preConfirm: () => {
                return {
                    cleanup: (<any>document.getElementById('chkConfirmCleanup')).checked
                }
            }
        };

        let isCompleteAudit = true;
        const conf = await TheSwal.fire({
            ...defaultConfirmStyle,
            icon: 'info',
            title: 'Checking Audit Status...',
            didOpen: () => {
                TheSwal.showLoading();
                loadPhotoStatuses(api, fstore, env, activeAudit.fsid)
                    .then((statuses: IAuditPhotoStatusesModel) => {
                        TheSwal.hideLoading();
                        if (statuses.forUpload > 0) {
                            TheSwal.close({
                                isConfirmed: false
                            });
                            history.push(`/audit/${activeAudit.fsid}/photos`);
                        }
                        else if (!statuses.allUploaded) {
                            isCompleteAudit = false;
                            TheSwal.update({
                                ...warningAuditDialog
                            });
                        }
                        else {
                            TheSwal.update({
                                ...completeAuditDialog
                            });
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        TheSwal.hideLoading();
                        TheSwal.update({
                            icon: 'error',
                            cancelButtonText: 'Close',
                            showCancelButton: true,
                            showConfirmButton: false,
                            text: 'Cannot get audit status. Please make sure you have a stable internet connection and try again.'                
                        })
                    });
            },
            allowEscapeKey: false,
            allowEnterKey: false,
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
        });
        
        if (!conf.isConfirmed) return;
        if (!isCompleteAudit) {
            const confAuditCompletion = await TheSwal.fire({
                ...defaultConfirmStyle,
                ...completeAuditDialog
            });
            if (!confAuditCompletion.isConfirmed) return;
        };

        try {
            await lineItemCol.reorder();
            await api.completeAudit(activeAudit.fsid);
            if (conf.value.cleanup) {
                const cleanupMgr = new PhotoCleanupManager(fstore);
                await cleanupMgr.cleanupAudit(activeAudit.fsid);
            }
            toast.success('Audit completed');
            setActiveAudit(null);
            localStorage.removeItem('active-audit-id');
            history.push('/home');
        }
        catch (err) {
            console.error(err)
            toast.error(err.message ?? `Unexpected error completing audit. Please try again in a moment.`);
        }
    }

    const allAudits = list?.sort((a, b) => b.auditDate - a.auditDate).map(a => ({ ...a, auditor: userList?.find(u => u.fsid == a.auditorFsid) }));
    const activeAuditAuditor = activeAudit && userList ? userList.find(u => u.fsid == activeAudit.auditorFsid) : null;

    const activeAuditLoaded = activeAudit && activeAuditId == activeAudit.fsid;

    return { 
        allAudits, 
        activeAudit, 
        activeAuditAuditor, 
        activeAuditAreas, 
        activeAuditExistings, 
        saveAudit, 
        removeAudit, 
        saveArea, 
        removeArea, 
        saveExisting, 
        master, 
        reorder, 
        emailAudit, 
        emailPhotos, 
        userList, 
        utilities, 
        sfdcOpps, 
        completeAudit,
        activeAuditLoaded,
    }
}

export type IOnsiteAuditModel = ReturnType<typeof useAudit>;
