import { useEffect, useState } from "react";
import { EV, EE } from 'enerx-shared';
import { confirmations } from "utility/confirmations";
import { useLocalEnv } from "./local.env";
import { NativeAuth } from "./native.auth";

export type AuthStatus = 'anon' | 'loading' | 'valid' | 'invalid';

export function useAuth() {
    const env = useLocalEnv();
    const [auth, setAuth] = useState<{ user?: EV.IUserEssentials, token?: string, org?: string, status: AuthStatus, fsOrgPrefix?: string, features?: EE.IOrgFeatures }>({ status: 'loading' });

    useEffect(() => {
        if (env.ready) {
            env.fb.auth.authStateReady()
                .then(() => NativeAuth.loginCurrent(env))
                .then(a => setAuth(a ? { ...a, status: 'valid', features: EE.orgFeatures(a.org, a.globals.cfg) } : { status: 'anon' }))
                .catch(console.error);    
        }
    }, [env.ready]);

    const logout = () => {
        env.fb.signOut();
        setAuth({ status: 'anon' });
    }

    const refreshToken = async () => {
        const a = await NativeAuth.loginCurrent(env);
        if (a) {
            setAuth({ ...a, status: 'valid', features: EE.orgFeatures(a.org, a.globals.cfg) });
            return a.token;
        }
        else {
            setAuth({ status: 'invalid' });
            return null;
        }
    }

    const requestPasswordReset = async () => {
        const res = await confirmations.confirmOrCancel({
            title: `To reset your password, we'll email you a link. Would you like to proceed?`,
        });
        if (res.isConfirmed) {
            env.fb.sendPasswordResetEmail(auth.user.email);
        }
    }

    const authenticated = (adata: any) => setAuth({ ...adata, status: 'valid', features: EE.orgFeatures(adata.org, adata.globals.cfg) });

    return { logout, requestPasswordReset, refreshToken, authenticated, ...auth, env }
}

export type IAuthModel = ReturnType<typeof useAuth>;

