import { IOnsiteAuditPhotoExt, ProgressStatus } from "model/photos";
import { useState } from "react";
import { AlertOctagon, ArrowUpRight, Cloud, CloudOff, Info, X } from "react-feather";
import { Button, Spinner } from "reactstrap";
import { PhotoInfoModal } from "./PhotoInfoModal";
import { EV } from 'enerx-shared';

const statusInfos: Record<ProgressStatus, { color: string, elem: JSX.Element }> = {
    waiting: { color: 'secondary', elem: <CloudOff size={14} /> },
    failed: { color: 'danger', elem: <AlertOctagon size={14}/> },
    complete: { color: 'success', elem: <Cloud size={14} /> },
    progress: { color: 'info', elem: <Spinner size='sm' color="primary" /> },
}

export const RenderPhoto = ({ photo, update, remove, upload }: { 
    photo: IOnsiteAuditPhotoExt, 
    update: (dto: Partial<EV.IOnsiteAuditPhoto>) => any, 
    remove?: () => any,
    upload?: { status: ProgressStatus, onRetry: () => void },
}) => {
    const [showInfo, setShowInfo] = useState<boolean | IOnsiteAuditPhotoExt>(false);
    const statusInfo = upload ? statusInfos[upload.status] : null;
    return <div style={{ position: 'relative' }}>
        <img 
            className='mt-2'
            src={photo.thumbUrl} 
            width={`230px`} 
            alt="Photo may become available in a few minutes"
            onError={e => { e.currentTarget.src = '/no_image_placeholder.png' }}
        />
        {
            remove && (
                <Button 
                    color='danger' 
                    size='sm' 
                    className='btn-icon' 
                    style={{top: '0', left: '195px', position: 'absolute'}}
                    onClick={remove}
                >
                    <X size={14} />
                </Button>    
            )
        }
        {
            upload && (
                <Button 
                    color={statusInfo.color} 
                    size='sm' 
                    className='btn-icon' 
                    style={{top: '0', left: '195px', position: 'absolute'}}
                    onClick={upload.status == 'failed' ? upload.onRetry : null}
                >
                {statusInfo.elem}
                </Button>
            )
        }
        <Button 
            color='primary' 
            size='sm' 
            className='btn-icon' 
            style={{top: '0', left: '5px', position: 'absolute'}}
            onClick={() => setShowInfo(photo)}
        >
            <Info size={14} />
        </Button>
        <a 
            href={photo.fullUrl}
            color='info' 
            className='btn-icon' 
            style={{top: '0', left: '45px', position: 'absolute'}}
            target="_blank"
        >
            <div className="btn btn-icon btn-info btn-sm">
                <ArrowUpRight size={14} />
            </div>
        </a>
        {
            showInfo && <PhotoInfoModal photo={showInfo as IOnsiteAuditPhotoExt} toggle={() => setShowInfo(false)} update={update} />
        }
    </div>;
}