import { model } from "model";
import { useState } from "react";
import { CheckCircle, Edit, PlusCircle } from "react-feather";
import { Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { AddEditAuditForm } from "./audit/AddEditAuditForm";
import { Link } from 'react-router-dom'

export const Home = () => {
    const { activeAudit } = model.useAuditCtx();
    const [showAdd, setShowAdd] = useState(false);
    return (
        <>
        {
            activeAudit && (
                <Row>
                    <Col xs={12} lg={{ offset: 2, size: 8 }}>
                        <Card className="bg-light-info">
                            <CardHeader>
                                <h1 className="text-primary m-1">
                                    <Edit size={48} className="me-2" />
                                    Active Audit
                                </h1>
                                <h2 className="m1">
                                    { `${activeAudit.customer} - ${activeAudit.address.city}, ${activeAudit.address.state}` }
                                </h2>                            
                            </CardHeader>
                            <CardBody>
                                <Link to={`/audit/${activeAudit.fsid}`}>
                                    <Button color="primary" block>Continue</Button>
                                </Link>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            )
        }
            <Row>
                <Col xs={12} sm={6} lg={{ offset: 2, size: 4 }}>
                    <Card className="bg-light-success">
                        <CardHeader>
                            <h1 className="text-primary m-1 text-center">
                                <PlusCircle size={48} className="me-2" />
                                Start New Audit
                            </h1>
                        </CardHeader>
                        <CardBody>
                            <Button color="primary" block onClick={() => setShowAdd(true)}>Create</Button>
                        </CardBody>
                    </Card>
                </Col>

                <Col xs={12} sm={6} lg={4}>
                    <Card className="bg-light-secondary">
                        <CardHeader>
                            <h1 className="text-primary m-1 text-center">
                                <CheckCircle size={48} className="me-2" />
                                View Past Audits
                            </h1>
                        </CardHeader>
                        <CardBody>
                            <Link to='/audits'><Button color="primary" block>Browse</Button></Link>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {
                showAdd && <AddEditAuditForm toggle={() => setShowAdd(false)} />
            }
        </>
    );
};