import Swal, { SweetAlertIcon } from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export const TheSwal = withReactContent(Swal);

export const defaultConfirmStyle = {
    icon: 'warning' as SweetAlertIcon,
    showCancelButton: true,
    customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-warning ms-1'
    },
    buttonsStyling: false,
}

const yesOrNo = (props: { text?: string, html?: string, title?: string }) => TheSwal.fire({
    ...props,
    title: props.title ?? 'Please make your decision',
    icon: 'question' as SweetAlertIcon,
    showCancelButton: true,
    confirmButtonText: 'Yes',
    cancelButtonText: 'No',
    customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-warning ms-1'
    },
    buttonsStyling: false,
});

const confirmOrCancel = (props: { text?: string, html?: string, title?: string, confirmButtonText?: string }) => TheSwal.fire({
    ...props,
    title: props.title ?? 'Are you sure?',
    ...defaultConfirmStyle,
    confirmButtonText: props.confirmButtonText ?? 'Confirm',
});

const errorNotification = (props: { text?: string, html?: string, title?: string, showConfirmButton?: boolean }) => TheSwal.fire({
    ...props,
    title: props.title ?? 'Error!',
    icon: 'error',
    confirmButtonText: 'OK',
    customClass: {
        confirmButton: 'btn btn-primary'
    },
    buttonsStyling: false                  
}); 

const warningNotification = (props: { text?: string, html?: string, title?: string, showConfirmButton?: boolean }) => TheSwal.fire({
    ...props,
    title: props.title ?? 'Warning!',
    icon: 'warning',
    confirmButtonText: 'OK',
    customClass: {
        confirmButton: 'btn btn-primary'
    },
    buttonsStyling: false                  
}); 

const thankYou = (props: { text?: string, html?: string }) => TheSwal.fire({
    ...props,
    title: 'Thank you!',
    icon: 'success',
    buttonsStyling: false,
    showConfirmButton: false,
});

const notification = (props: { text?: string, html?: string }) => TheSwal.fire({
    ...props,
    title: 'Attention!',
    icon: 'warning',
    buttonsStyling: false,
    showConfirmButton: false,
}); 

export const confirmations = { confirmOrCancel, errorNotification, thankYou, notification, warningNotification, yesOrNo };
