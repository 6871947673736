import { Capacitor, CapacitorHttp, HttpOptions } from '@capacitor/core';
import { Device } from '@capacitor/device';
import * as fbWeb from 'fb-web';
import { useEffect, useState } from 'react';
import { appEnv } from './app.env';
import { EV } from 'enerx-shared'
async function nativeFetch(url: string, init?: RequestInit): Promise<Response> {
    const httpOptions = {
        url,
        method: init?.method || 'GET',
        headers: init?.headers || {},
        data: init?.body || {},
    } as HttpOptions;
    
    const response = await CapacitorHttp.request(httpOptions);
    
    // Convert the CapacitorHttp response to a fetch response
    const fetchResponse = new Response(JSON.stringify(response.data), {
        status: response.status,
        headers: response.headers,
    });
    
    return fetchResponse;
};

async function localEnv() {
    let env: EV.EnvTyp;
    let platform: 'web' | 'android' | 'ios'  = 'web'
    if (Capacitor.isNativePlatform()) {
        // we will need separating stage vs prod during build unfortunately 
        // or enable "stage mode" inside the app
        const info = await Device.getInfo();
        env = appEnv(info)    
        console.log('local env', env, process, info.platform, info.isVirtual)
        //env = fbWeb.WebEnv.Stage;
        platform = info.platform;
    }
    else {
        env = fbWeb.defaultEnv();
    }

    const cfg = fbWeb.cfg(env, platform);
    console.log('Initialized', env, cfg.eeapi, cfg.photoBase, env == EV.EnvTyp.Stage ? 'stage' : 'not stage')
    return {
        env,
        cfg,
        platform,
    }
}

export function useLocalEnv() {
    const [env, setEnv] = useState({ ready: false } as { env: EV.EnvTyp, cfg: fbWeb.ICfg, fb: fbWeb.IFb, ready: boolean, platform: 'web' | 'android' | 'ios' });
    useEffect(() => {
        localEnv().then(res => {
            const fb = fbWeb.fb(res.env, res.cfg.platform);
            setEnv({ ...res, fb, ready: true });
        })
    }, []);

    return { ...env, fetch: Capacitor.isNativePlatform() ? nativeFetch : window.fetch.bind(window) };
}

export type ILocalEnv = ReturnType<typeof useLocalEnv>;
