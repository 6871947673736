import { EV } from "enerx-shared"
import { ILineItemModel, model } from "model";
import { useState } from "react";
import { Camera, Check, Copy, Edit, Trash2, XCircle } from 'react-feather';
import { Badge, Button, Form, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink } from "reactstrap";
import { confirmations } from 'utility/confirmations';
import { UseInputExisting, isAnyModified, useLinePropertiesFilter, useRequiredValidation } from './hooks/lineItem.hooks';
import { LineItemNotes } from './LineItemNotes';
import { LineItemPhotos } from './LineItemPhotos';
import { LineItemProperties } from './LineItemProperties';
import { useLineProperties } from './hooks/useLineProperties';
import { AddEditArea } from "views/audit/AddEditArea";
import { ReactComponent as AddAreaIcon } from './add-area.svg';
import { useGeoRequestPermission } from 'model/photos/hooks';

export type InitialMode = 'props' | 'photos' | 'camera' | 'notes';

const dialogStyle = {
    maxWidth: '100%',
    padding: '1%',
    margin: '0',
    paddingTop:'50px'
}

const floatingBtnStyle = {
    position: 'fixed',
    right: '0px',
    opacity: '0.7',
    padding: '1rem',
    zIndex: 9999
} as any;

const notesBtnStyle = {
    ...floatingBtnStyle,
    top: '220px',
};

const photoBtnStyle = {
    ...floatingBtnStyle,
    top: '300px',
};

const addAreaBtnStyle = {
    ...floatingBtnStyle,
    top: '370px',
    padding: '8px 8px',
};

const cancelBtnStyle = {
    ...floatingBtnStyle,
    top: '440px',
};

const okBtnStyle = {
    ...floatingBtnStyle,
    top: '510px',
}

export const AddEditLineItem = ({ model: liModel, original, initialMode, onComplete, selectedArea }: { 
                                    model: ILineItemModel, 
                                    original?: EV.IOnsiteAuditLineItem, 
                                    initialMode: InitialMode, 
                                    onComplete: (status?: 'cancel'|'ok') => void, 
                                    selectedArea: EV.IArea,
                                }) => {
    const audit = model.useAuditCtx();
    const { auth } = model.useAppCtx();
    const [mode, setMode] = useState<InitialMode>(initialMode);
    const [showNewArea, setShowNewArea] = useState(false);

    useGeoRequestPermission();

    original = original ?? { 
        auditFsid: audit.activeAudit.fsid,
        areaFsid: liModel.areaFsid,
        burnHours: selectedArea?.burnHours ?? audit.activeAudit.burnHours ?? null,
        fixtureHeight: selectedArea?.fixtureHeight ?? audit.activeAudit.fixtureHeight ?? null,
        ceilingHeight: selectedArea?.ceilingHeight ?? audit.activeAudit.ceilingHeight ?? null,
        voltage: selectedArea?.voltage ?? audit.activeAudit.voltage ?? null,
        ac: selectedArea?.ac ?? audit.activeAudit.ac ?? null,
        auditAttributes: [],
    } as EV.IOnsiteAuditLineItem;
    const [lineItem, setLineItem] = useState({
        ...original,
        fsid: original.fsid ?? EV.utils.str.generateString(),
        id: original.id ?? (liModel.maxId ?? 0) + 1
    });
    const [disabled, setDisabled] = useState(false);

    const isNewLineItem = () => !original?.fsid;

    const close = (status?: 'cancel'|'ok') => {
        if (status != 'ok') {

        }
        onComplete(status);
    }

    const {properties} = useLineProperties(lineItem, setLineItem);

    const filter = useLinePropertiesFilter();
    const requiredValidation = useRequiredValidation(properties, auth.features, filter, setMode);

    const cancel = async () => {
        if (isAnyModified(properties)) {
            const conf = await confirmations.confirmOrCancel({ title: 'Do you want to close without saving your changes?', text: `Press Cancel to continue editing or to save your changes.`, confirmButtonText: 'Yes' });
            if (!conf.isConfirmed) return;
        }

        if (isNewLineItem()) {
            liModel.removeAllPhotos(lineItem);
        }
        close();
    }

    const deleteItem = async () => {
        if (original) {
            await liModel.remove(original);
        }
        close();
    }

    const save = async () => {
        if (!requiredValidation.validateAndAct()) return false;

        const existingFixture = properties.find(p => p.field == 'existingFixtureFsid') as UseInputExisting;
        const customExistingFixture = {};
        if (existingFixture.isCustomOpened) {
            const existingFixtureFsid = await audit.saveExisting(existingFixture.custom);
            customExistingFixture['existingFixtureFsid'] = existingFixtureFsid;
        }

        const lineToSave = Object.assign(
            lineItem,
            customExistingFixture // here custom logic for 'existing' see above
        )

        await liModel.save(lineToSave);
        return lineToSave;
    }

    const duplicateItem = async () => {
        if (isAnyModified(properties)) {
            const conf = await confirmations.yesOrNo({title: `You have unsaved changes, do you want to save current changes before duplicating this line?`, text: `If you do not save before duplicating, the changes will not be reflected on this line or the duplicated line.`});
            if (conf.isConfirmed) {
                const savedLine = await save();
                if (savedLine) {
                    liModel.duplicate(savedLine);
                    close();
                    return;
                }
            }
        }

        liModel.duplicate(original);
        close();
    }

    const goNotes = () => {
        setMode('notes');
    }

    const goCamera = () => {
        setMode('camera');
    }

    const addArea = () => save().then(ok => ok && setShowNewArea(true));

    return (
        <Modal style={dialogStyle} isOpen>
            <ModalHeader>
                <Nav tabs>
                {
                    !isNewLineItem() && (
                        <NavItem key='badge'>
                            <Badge style={{margin: '10px 15px 0 0'}} color="light-primary"> {EV.utils.fmt.id(lineItem?.id ?? '', 3)}</Badge>
                        </NavItem>
                    )
                }
                    <NavItem key='props' className='ps-0 pe-0 m-0'>
                        <NavLink className='ps-50 pe-50 m-0' active={mode == 'props'} onClick={() => setMode('props')}>
                            Properties
                        </NavLink>
                    </NavItem> 
                    <NavItem key='notes' className='ps-0 pe-0 m-0'>
                        <NavLink className='ps-50 pe-50 m-0' active={mode == 'notes'} onClick={() => setMode('notes')}>
                            Notes
                        </NavLink>
                    </NavItem> 
                {
                    <NavItem key='photos' className='ps-0 pe-0 m-0'>
                        <NavLink className='ps-50 pe-50 m-0' active={mode == 'photos'} onClick={() => setMode('photos')}>
                            Photos
                        </NavLink>
                    </NavItem>
                }
                </Nav>
            </ModalHeader>
            <ModalBody>
                {mode == 'photos' && <LineItemPhotos mode={mode} lineItem={lineItem} auditFsid={liModel.auditFsid} />}
                {mode == 'camera' && <LineItemPhotos mode={mode} lineItem={lineItem} auditFsid={liModel.auditFsid} />}
                <Form onSubmit={async (e) => {
                    e.preventDefault();
                    setDisabled(true);
                    const success = await save();
                    if (success) {
                        close('ok');
                    }
                    setDisabled(false);
                }}>

                {mode == 'props' && <LineItemProperties lineItem={lineItem} properties={properties} useLinePropertiesFilter={filter}></LineItemProperties>}
                {mode == 'notes' && <LineItemNotes properties={properties} useLinePropertiesFilter={filter}></LineItemNotes>}

                <div key='controls' className="form-group mt-2 text-end">
                    {mode == 'props' && !isNewLineItem() && <Button color='danger' className="me-1 p-1" onClick={deleteItem}><Trash2/></Button>}
                    {mode == 'props' && !isNewLineItem() && <Button color='warning' className="me-1 p-1" onClick={duplicateItem}><Copy/></Button>}
                    
                    {mode == 'props' && <Button color="dark" className="me-1" onClick={addArea} style={{padding: '8px 8px'}}>
                            <AddAreaIcon />
                        </Button>}
                    {mode == 'props' && <Button color="secondary" className="me-1 p-1" onClick={cancel}><XCircle/></Button>}
                    {mode == 'props' && <Button color="primary" className="p-1" disabled={disabled} type="submit"><Check/></Button>}
                    {(mode != 'props' || filter.applyFilters(properties, auth.features).length > 6) && <>
                        {mode != 'notes' && <Button style={(mode === 'photos' || mode === 'camera') ? photoBtnStyle : notesBtnStyle} color="success" onClick={goNotes}><Edit/></Button>}
                        {mode != 'photos' && mode != 'camera' && <Button style={photoBtnStyle} color="info" onClick={goCamera}><Camera/></Button>}
                        <Button style={addAreaBtnStyle} color="dark" onClick={addArea}><AddAreaIcon/></Button>
                        <Button style={cancelBtnStyle} color="secondary" onClick={cancel}><XCircle/></Button>
                        <Button style={okBtnStyle} color="primary" disabled={disabled} type="submit"><Check/></Button>
                    </>}
                </div>

            </Form>
            {
                showNewArea && <AddEditArea origin={null} toggle={() => setShowNewArea(false)} />
            }
            </ModalBody>
        </Modal>
    )
}