import { useMemo } from "react";
import { Api } from "./api";
import { useAuth } from "./auth";
import { Log } from "./log";
import { useFstore } from "./fstore";
import { PhotoCleanupManager } from "./photos";
import { TheSwal, defaultConfirmStyle } from "utility/confirmations";
import { toast } from "react-toastify";


export function useApp() {
    const auth = useAuth();
    const { ready, fstore } = useFstore(auth);

    const svc = useMemo(() => {
        if (ready) {
            const log = new Log(auth.user, fstore, auth.env.cfg);
            const api = new Api(auth, log, auth.env);
            return { fstore, log, api, ready }
        }
        return { ready: false }
    }, [fstore, ready]);

    const checkStorage = async () => {
        const photoCleanup = new PhotoCleanupManager(fstore);
        const res = await TheSwal.fire({
            ...defaultConfirmStyle,
            icon: 'info',
            title: 'Storage Stats',
            showConfirmButton: false,
            showCloseButton: false,
            showCancelButton: false,
            didOpen: async () => {
                TheSwal.showLoading();
                const stats = await photoCleanup.refreshStats();
                const [messageText, showConfirmButton, cancelButtonText] = stats.completed.photos > 0 ? [
                    `${stats.completed.photos} photos belong to non-active audits and could be removed in order to cleanup your storage. Please click OK to remove them.`,
                    true,
                    'Cancel'
                ] : [
                    `No photos to remove.`,
                    false,
                    'Close',
                ]
                const rows = stats.list.map(r => `<tr>
                    <td class='text-start'>${r.audit ? `${r.audit.customer} - ${r.audit.projectName}` : 'n/a'}</td>
                    <td class='text-nowrap'>${r.audit ? r.audit.status : 'removed'}</td>
                    <td class='text-end text-nowrap'>${r.count}</td>
                </tr>`);
                TheSwal.hideLoading();
                TheSwal.update({
                    html: `<table class='table table-sm'>
                        <thead>
                            <tr>
                                <th class='text-start'>Audit</th>
                                <th class='text-nowrap'>Status</th>
                                <th class='text-end text-nowrap'>Photos</th>
                            </tr>
                        </thead>
                        <tbody>
                            ${rows.join('\n')}
                        </tbody>
                        <tfoot>
                            <tr>
                                <th class='text-start' colspan='2'>Total to remove</th>
                                <th class='text-end'>${stats.completed.photos}</th>
                            </tr>
                        </tfoot>
                    </table>
                    <p><strong>${messageText}</strong></p>`,
                    showConfirmButton,
                    showCloseButton: true,
                    showCancelButton: true,
                    cancelButtonText,                    
                });
            }
        });

        if (res.isConfirmed) {
            await photoCleanup.cleanup();
            toast.success('Storage cleaned up');
        }
    }

   
    return { auth, ...svc, env: auth.env, checkStorage }
}

export type IAppModel = ReturnType<typeof useApp>;
