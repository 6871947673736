import { EV } from 'enerx-shared';
import { model } from "model";
import { Check, Filter} from "react-feather";
import { useHistory } from 'react-router';
import { Button, Modal, ModalBody, ModalHeader, Row, Col } from "reactstrap";

export const AreaSelector = ({ toggle, onSelect, selectedArea }: { toggle: () => void, onSelect: (area: EV.IArea) => void, selectedArea: EV.IArea }) => {
    const { activeAuditAreas, master, activeAudit } = model.useAuditCtx();
    const history = useHistory();

    return (
        <Modal toggle={toggle} style={{paddingTop:'50px'}} isOpen size="lg">
            <ModalHeader tag='h4' toggle={toggle}>
                Filter By Areas
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col xs={6} className="pe-25">
                        <Button color="primary" className="pt-70 pb-75 ps-50 pe-50 mb-1" onClick={() => history.push(`/audit/${activeAudit.fsid}/areas`)}>Manage Areas</Button>
                    </Col>
                    <Col xs={6} className="ps-25"><div className='text-end'>
                        <Button color="primary" outline className="p-50 mb-1" onClick={() => onSelect(null)}><Check size={22} className="pe-25" />List all items</Button>
                    </div></Col>    
                </Row>
                <Row key='header' className="border-bottom m-50 d-none d-sm-flex">
                    <Col xs={5}>
                        <h5 className="text-primary mt-75"><b>Area Name</b></h5>
                    </Col>
                    <Col xs={5} className="">
                        <h5 className="text-primary mt-75 d-none d-md-flex"><b>Area Type</b></h5>
                    </Col>
                    <Col xs={2} className="text-end ps-0"></Col>
                </Row>
                {
                    activeAuditAreas.map(area => (
                        <Row key={area.fsid} className="border-bottom m-50">
                            <Col xs={5}>
                                <h5 className="text-primary mt-75">{area.name}</h5>
                            </Col>
                            <Col xs={5} className="">
                                <h5 className="text-primary mt-75 d-none d-md-flex">{master.areaType?.find(a => a.id == area.areaType)?.label ?? '-'}</h5>
                            </Col>
                            <Col xs={2} className="text-end ps-0">
                                <div>
                                    <Button size="sm" outline={selectedArea?.fsid != area.fsid} color="primary" className="mb-50" onClick={() => onSelect(area)}><Filter size={24} /></Button>
                                </div>
                            </Col>
                        </Row>
                    ))
                }
            </ModalBody>
        </Modal>
    )
}