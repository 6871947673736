import { useEffect } from 'react';
import { formInput, UseInputExisting, UseInputNumber, UseFixtureTypeInputPicker } from './lineItem.hooks';

export const useAutomateExistingTypeNONE = (properties: formInput[]) => {
    const existingFType = properties.find(p => p.field == 'existingFType') as UseFixtureTypeInputPicker;
    const existingFixtureFsid = properties.find(p => p.field == 'existingFixtureFsid') as UseInputExisting;
    const qty = properties.find(p => p.field == 'qty') as UseInputNumber;

    useEffect(() => {
        if (existingFType?.value === 'NONE') {
            if (qty.value === '') {
                qty.setValue(0);
            }
            if (existingFixtureFsid.value === '') {
                existingFixtureFsid.setValue('NONE');
            }
        }
        else {
            if (qty.value === 0) {
                qty.setValue('');
            }
            if (existingFixtureFsid.value === 'NONE') {
                existingFixtureFsid.setValue('');
            }
        }
    }, [existingFType.value]);
}