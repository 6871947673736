import { IOnsiteAuditPhotoModel } from "model/photos/hooks";
import { PhotoSwiper } from "./PhotoSwiper";
import { RenderPhoto } from "./RenderPhoto";
import { ChangeEvent, useEffect, useRef } from "react";
import { Button, Label } from "reactstrap";
import { Camera, Trash2 } from "react-feather";

export const AttachedPhotos = ({ model, mode, size }: { model: IOnsiteAuditPhotoModel, mode: 'photos' | 'camera', size?: 'lg' | 'std' }) => {
    const inputRef = useRef(null);

    useEffect(() => {
        if (mode == 'camera') {
            inputRef.current.click();
            // startTakePhoto();
        }
    }, [])

    const startTakePhoto = () => {
        // model.startGeoLocation();
    }

    const onTakePhoto = (e: ChangeEvent<HTMLInputElement>) => {
        model.add(e.target?.files?.[0]);
        // model.stopGeoLocation();
    }

    return <>
        <div className='d-flex justify-content-center'>
            <div>
                { model.list.length > 0 && <Button style={{marginRight:'10px'}} color='danger' onClick={model.confirmAndRemoveAll}><Trash2/></Button>}
            </div>
            <div >
                <Label htmlFor="capture_camera" className='w-100' onClick={startTakePhoto}>
                    <span className='btn btn-primary d-block w-100 fs-4 ps-1 pe-1'>
                        <Camera className='me-1'/>
                        Take Photo
                    </span>
                </Label>
                <input 
                    className='d-none' 
                    type="file" 
                    ref={inputRef}
                    id="capture_camera" 
                    name="capture_camera"
                    accept="image/*"
                    onChange={onTakePhoto} 
                    capture="environment"
                />
            </div>
        </div>

        <div className='mt-1'>
        {model.list.length > 0 ? <></> : <div style={{height: '372px'}}></div>} 
        {
            <PhotoSwiper size={size} slides={
                model.list.map(photo => <RenderPhoto 
                    key={photo.record.createdAt} 
                    photo={photo} 
                    update={dto => model.update(photo.record.fsid, dto)}
                    remove={() => model.remove(photo.record)}
                />)
            }/>    
        }
        </div>
    </>
}