import { CheckCircle, Home } from 'react-feather'

const basicNav = (id: string, icon: JSX.Element, title?: string, children?: any[]) => ({
    id,
    title: title ?? id.charAt(0).toUpperCase() + id.slice(1),
    icon,
    navLink: `/${id}`,
    children,
})

const iconProps = { size: 20 };

export const useNavigation = () => {
    const nav = [basicNav('home', <Home {...iconProps} />)];
    nav.push(basicNav('audits', <CheckCircle {...iconProps} />, 'Past Audits'));

    return nav;
}