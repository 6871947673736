import { EV } from 'enerx-shared';
import { model } from "model";
import { useState } from "react";
import { Alert, Button, Col, Row } from "reactstrap";
import { AddEditLineItem, InitialMode } from "./AddEditLineItem";
import { LineItem } from './LineItem';

export const LineItems = ({ audit, selectedArea }: { audit: EV.IOnsiteAudit, selectedArea: EV.IArea }) => {
    const { master, activeAuditExistings } = model.useAuditCtx();
    const lineItemModel = model.useLineItem(master, activeAuditExistings, audit.fsid, selectedArea?.fsid);
    const list = lineItemModel.list;
    const [modal, setModal] = useState<'none' | { original?: EV.IOnsiteAuditLineItem, initialMode: InitialMode }>('none');
    const msg = selectedArea ? `This area doesn't have any line item yet.` : `This audit doesn't have any line item yet.`;
    
    const AddLineItemRow = () => (
        <Row>
            <Col className="text-end mb-1">
                <Button color="primary" onClick={() => setModal({ initialMode: 'props' })}>Add Line Item</Button>
            </Col>
        </Row>
    );

    return (
        <>
        {
            list?.length > 0 && <AddLineItemRow />        
        }        
        <Row className='match-height'>
        {
            list?.map(itm => <LineItem itm={itm} onClick={() => setModal({ original: itm, initialMode: 'props' })} key={itm.fsid}/>)
        }
        </Row>
        {
            list && list.length == 0 && (
                <Row>
                    <Col xs={12}>
                        <Alert color="info" className="m-2">
                            <div className="alert-body">
                                {msg} Use Add button to create.
                            </div>
                        </Alert>
                    </Col>
                </Row>
            )            
        }
        <AddLineItemRow />
        {
            modal != 'none' && <AddEditLineItem 
                model={lineItemModel} 
                onComplete={(status?: 'cancel'|'ok') => { 
                    setModal('none'); 
                    if ((!modal.original && status == 'ok')) setTimeout(() => { setModal({initialMode: 'props' }) }, 0); }
                } 
                selectedArea={selectedArea}
                {...modal} 
            />
        }
        </>
    );
}