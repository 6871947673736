import navbar from './navbar';
import layout from './layout';
// import { authSlice, IAuthState } from './auth';
import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useSelector } from 'react-redux';

export interface IRootState {
  // auth: IAuthState;
  layout: any; 
  navbar: any;
}

const store = configureStore({
    reducer: { 
        navbar, 
        layout, 
        // auth: authSlice.reducer, 
        // api: api.reducer,
        // help: help.reducer,
    },
    // middleware: getDefaultMiddleware => {
    //   return getDefaultMiddleware({
    //     serializableCheck: false
    //   }).concat(api.middleware)
    // }
});

export const useAppSelector: TypedUseSelectorHook<IRootState> = useSelector;

export default store;
